import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapComponentProps } from "../../typeDefination";
import { useAppDispatch } from "../../appState/reduxTypedHooks";
import { setSelectedAddress } from "../../appState/componentSlices/addressSearchSlice/addressSearchSlice";

const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "80vh",
  position: "relative",
};

const MapComponent: React.FC<MapComponentProps> = ({ lat, lng, apiKey }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [map, setMap] = useState<any | null>(null);
  const [marker, setMarker] = useState<any| null>(null);

  const fetchAddress = async (latitude: number, longitude: number) => {
    try {
      const response = await fetch(
        `https://api.olamaps.io/places/v1/reverse-geocode?latlng=${latitude},${longitude}&api_key=${apiKey}`
      );
      const data = await response.json();

      if (data && data.results && data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address;
        dispatch(
          setSelectedAddress({
            lat: latitude,
            lng: longitude,
            address: formattedAddress,
          })
        );
      } else {
        console.log("No address found for this location.");
      }
    } catch (error) {
      console.error("Failed to fetch address:", error);
    }
  };

  useEffect(() => {
    if (!mapRef.current) return;

    const initializedMap = L.map(mapRef.current, {
      zoomControl: false,
      attributionControl: false,
      maxZoom: 22,
      minZoom: 5,
      zoomAnimation: true,
      fadeAnimation: true,
    }).setView([lat, lng], 18);

    L.tileLayer(
      `https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/{z}/{x}/{y}.png?api_key=${apiKey}`,
      {
        maxZoom: 22,
        tileSize: 256,
        zoomOffset: 0,
      }
    ).addTo(initializedMap);

    const redIcon = L.icon({
      iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png",
      iconSize: [40, 40],
    });

    const initialMarker = L.marker([lat, lng], {
      icon: redIcon,
      draggable: true,
    }).addTo(initializedMap);
    setMarker(initialMarker);
    setMap(initializedMap);

    return () => {
      initializedMap.remove();
    };
  }, [lat, lng, apiKey]);

  useEffect(() => {
    if (!map || !marker) return;

    const handleMapClick = (e: any) => {
      const { lat, lng } = e.latlng;

      marker.setLatLng([lat, lng]);
      fetchAddress(lat, lng);
    };

    map.on("click", handleMapClick);

    return () => {
      map.off("click", handleMapClick);
    };
  }, [map, marker]);

  return <div style={mapContainerStyle} ref={mapRef} />;
};

export default MapComponent;
