export const generateConfetti = (length = 100) => {
    const confettiElements = [];
    for (let i = 0; i < length; i++) {
      const size = 10;
      const left = Math.random() * 100;
      const delay = Math.random() * 3;
      const duration = Math.random() * 2 + 3;
      const color = `hsl(${Math.random() * 360}, 100%, 50%)`;
      confettiElements.push(
        <div
          key={i}
          className="confetti"
          style={{
            width: `${size}px`,
            height: `${size}px`,
            left: `${left}%`,
            animationDelay: `${delay}s`,
            animationDuration: `${duration}s`,
            backgroundColor: color,
            zIndex: 10000,
          }}
        />
      );
    }
    return confettiElements;
  };