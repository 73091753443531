import { Navigate, useLocation } from "react-router";
import { useAppSelector } from "../../appState/reduxTypedHooks";

function RequireAuth({ children }: { children: JSX.Element }) {
  const isLoggedIn = useAppSelector((state) => state.userOtp.isLoggedIn);
  const isGuestUser= useAppSelector((state) => state.userOtp?.userDetails?.guest);
  const location = useLocation();
  if (!isLoggedIn && !isGuestUser) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export { RequireAuth };
