import { useEffect, useState } from "react";
import { setModal } from "../../appState/componentSlices/userSlice/userLoginSlice";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import useCart from "../../hooks/useCart";
type CartQuantityButton = {
  quantity?: number;
  showAddButton?: boolean;
  product?: any;
  setProductQuantity?: any;
};
const CartQuantityButton = ({
  quantity = 1,
  showAddButton = true,
  product,
  setProductQuantity,
}: CartQuantityButton) => {
  const { addToCart, removeFromCart } = useCart();
  const [counter, setCounter] = useState(quantity);
  const [inputCount, setInputCount] = useState(quantity)
  const [showInput, setShowInput] = useState(false);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.userOtp.isLoggedIn);

  const productCardList = useAppSelector((state) => state.productInCart.productInCart ||[])

    useEffect(() => {
    if (productCardList?.products && product?.productDetails?._id) {
      const cartProduct = productCardList.products.find(
        (item: { productDetails: { _id: any; }; }) => item.productDetails._id === product.productDetails._id
      );

      if (cartProduct) {
        setCounter(cartProduct.productQuantity);
        setInputCount(cartProduct.productQuantity);
      }
    }
  }, [productCardList, product]);

  const handleAddToCart = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    addToCart({ product, quantity: counter + 1 });
    setCounter(counter + 1);
  };

  const decreaseCounter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (counter > 1) {
      removeFromCart({ product, quantity: counter - 1 });
      setCounter(counter - 1);
    } else {
      removeFromCart({ product, quantity: counter - 1 });
      setShowInput(false);
    }
  }

  const handleCountToCart = (event: number) => {
    setInputCount(event)
    if (event > 0) {
      if (event < counter) {
        removeFromCart({ product, quantity: event });
        setCounter(event);
      } else {
        addToCart({ product, quantity: event });
        setCounter(event);
      }
    } else if (event === 0) {
      removeFromCart({ product, quantity: event });
      setShowInput(false);
      setCounter(event);
    }
  };

  useEffect(() => {
    setProductQuantity(counter);
    setInputCount(counter)
    if (counter) {
      setShowInput(true);
    }
  }, [counter]);

  return (
    <>
      {!showInput && showAddButton ? (
        <button onClick={handleAddToCart} className="add-to-cart-button">
          ADD
        </button>
      ) : (
        <div className="add-to-cart-btn-container">
          <button
            onClick={decreaseCounter}
            className="add-to-cart-btn substract-btn"
          >
            -
          </button>
          <input
            className="add-to-cart-input"
            type="number"
            min={1}
            value={inputCount}
            onChange={(e) => handleCountToCart(parseInt(e.target.value))}
            onClick={(e) => e.stopPropagation()}
          />
          <button onClick={handleAddToCart} className="add-to-cart-btn">
            +
          </button>
        </div>
      )}
    </>
  );
};

export { CartQuantityButton };
