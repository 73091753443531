import { useAppDispatch } from "../../appState/reduxTypedHooks";

type PaymentAccordionProps = {
  checkoutData: {
    paymentMethod: string;
  };
  setCheckoutData: any;
};
function PaymentAccordion({
  checkoutData,
  setCheckoutData,
}: PaymentAccordionProps) {
  const dispatch = useAppDispatch();
  return (
    <div className="payment-accordion-container">
     
      <select
        required
        className="payment-select"
        name="paymentMethod"
        id="paymentMethod"
        onChange={(e) => {
          dispatch(
            setCheckoutData({ paymentMethod: e.target.value })
          );
        }}
        value={checkoutData.paymentMethod}
      >
        <option className="payment-option" value="cash">
          Cash
        </option>
        <option className="payment-option" value="card">
          Card
        </option>
        <option className="payment-option" value="upi">
          UPI
        </option>
      </select>
      
    </div>
  );
}

export { PaymentAccordion };
