const isProduction =
  process.env.ENV_NAME === "production" ||
  process.env.NODE_ENV === "production";
// const baseURL = isProduction ? `.netlify/functions/app/` : "";

// adding this for testing
const baseURL = ""; 

export const API = {
  ITEMS: {
    GET_ALL_ITEMS: `${baseURL}getItems`,
  },
  ADDRESS:{
   SEARCH_ADDRESS:`${baseURL}api/search-address`
  },
  PRODUCTS: {
    GET_ALL_PRODUCTS: `${baseURL}api/product/all`,
    GET_ALL_PRODUCTS_BY_BRAND_OR_CATEGORY: `${baseURL}api/product/getAllProductsByBrandAndCategory`,
    SEARCH_PRODUCTS:`${baseURL}api/product/search/productName`,
    SEARCH_PRODUCTS_BY_BRAND_OR_CATEGORY:`${baseURL}api/product/search/categorieOrBrands/productName`,
    GET_POPULAR_PRODUCTS:`${baseURL}api/product/popular`,
    GET_PRODUCT:`${baseURL}api/product/id`,
    GET_SIMILAR_PRODUCTS: `${baseURL}api/product/getSimilarProducts`
  },
  CART: {
    GET_PRODUCT_IN_CART: `${baseURL}api/getCartProducts`,
    ADD_TO_CART: `${baseURL}api/cart/addToCart`,
    REMOVE_FROM_CART: `${baseURL}api/cart/removeFromCart`,
    MERGE_FROM_CART: `${baseURL}api/mergeCart`,
  },
  CHECKOUT: {
    CHECKOUT: `${baseURL}api/order/placeOrder`,
  },
  USER: {
    VERIFY_OTP: `${baseURL}api/verify-otp`,
    LOGIN_USER: `${baseURL}api/auth/login`,
    GET_USER_DETAILS: `${baseURL}api/getUser`,
    LOGOUT_USER: `${baseURL}api/auth/logout`,
    UPDATE_USER_DETAILS: `${baseURL}api/user/updateUser`,
    SEND_OTP: `${baseURL}api/send-otp`,
    UPDATE_USER_IMAGE:`${baseURL}api/user/upload-profile`,
    GUEST_USER:`${baseURL}api/auth/guestUser`,
    VERIFY_USER_CONTACT:`${baseURL}api/verifyUserContact`,
  },
  ORDER: {
    GET_ALL_ORDERS: `${baseURL}api/order/getAllOrders`,
    GET_ORDER_STATUS: `${baseURL}api/order/getOrderStatus`,
    REPEAT_ORDER: `${baseURL}api/order/repeatOrder`,
    GET_SINGLE_ORDER: `${baseURL}api/order/getOrderById`,
  },
  CHECK_DISTANCE_WITHIN_RANGE:`${baseURL}api/checkDistanceWithinRange`,
};

// export const API = {
//   ITEMS: {
//     GET_ALL_ITEMS: `/getItems`,
//   },
//   PRODUCTS: {
//     GET_ALL_PRODUCTS: `/api/product/all`,
//   },
//   CART: {
//     GET_PRODUCT_IN_CART: `/api/getCartProducts`,
//     ADD_TO_CART: `/api/cart/addToCart`,
//     REMOVE_FROM_CART: `/api/cart/removeFromCart`,
//   },
//   CHECKOUT: {
//     CHECKOUT: `/api/order/placeOrder`,
//   },
//   USER: {
//     VERIFY_OTP: `/api/auth/verify`,
//     LOGIN_USER: `/api/auth/login`,
//     GET_USER_DETAILS: `/api/getUser`,
//     LOGOUT_USER: `/api/auth/logout`,
//     UPDATE_USER_DETAILS: `/api/user/updateUser`,
//   },
//   ORDER: {
//     GET_ALL_ORDERS: `/api/order/getAllOrders`,
//     GET_ORDER_STATUS: `/api/order/getOrderStatus`,
//     REPEAT_ORDER: `/api/order/repeatOrder`,
//     GET_SINGLE_ORDER: `/api/order/getOrderById`,
//   },
// };
