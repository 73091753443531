import { UserDetailsInterface } from "../../appState/componentSlices/userSlice/@types/User.Initerface";
import { useAppSelector } from "../../appState/reduxTypedHooks";
import Image from "../image/Image";

const UserAvatar = () => {
  const number = window.sessionStorage.getItem("PStoreApp-phone-number") || "";
  const userDetails: UserDetailsInterface = useAppSelector(
    (state) => state.userOtp.userDetails
  );
  
  return (
    <div className="user-avatar-container">
      <div className="profile-image">
        {userDetails?.image?.secureUrl ? (
          <Image
            src={userDetails?.image?.secureUrl || ""}
            alt="Preview"
            className="profile-image-img"
          />
        ) : (
          <></>
        )}
        <p>{number[0]}</p>
      </div>
    </div>
  );
};

export { UserAvatar };
