import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import useCheckout from "../../hooks/useCheckout";
import { getUserDetails, mergeUserProductFromCart, sendOtp, setMessage, setModal, verifyUserContact } from "../../appState/componentSlices/userSlice/userLoginSlice";
import { toastMessages } from "../../constant/toastMessage";
import { setCheckoutData } from "../../appState/componentSlices/checkoutSlice/checkoutSlice";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import LoadingSpinner from "../loader/LoadingSpinner";
import { useSelector } from "react-redux";
import { RootState } from "../../appState/store";
import AddressDrawer from "../addressDrawer/AddressDrawer";
import { checkLocationDistance, fetchCurrentLocation } from "../../appState/componentSlices/locationSlice/locationSlice";
import { API } from "../../utils/api/apiPaths";
import { Shimmer } from "../shimmer/Shimmer";
import { getStoredLocation } from "../../utils/getStoredLocation";
import { setFinalSelectedAddress, setSelectedAddress } from "../../appState/componentSlices/addressSearchSlice/addressSearchSlice";
import ReactPortal from "../../utils/ReactPortals";
import { Modal } from "../modal/Modal";
import { OtpVerification } from "../otpVerification/OtpVerification";
import { SignInOptionsModal } from "../signInOptionsModal/SignInOptionsModal";
import { responseMessages } from "../../constant/responseMessage";


const CheckoutForm = () => {

  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [signInOptions, setSignInOptions] = useState(false);
  const[isPhoneNumberVerified , setIsPhoneNumberVerified] = useState(false);
  const [otpScreen, setOtpScreen] = useState(false);

  const dispatch = useAppDispatch();
  const {
    userOtp: {
      userDetails,
      message,
      loading
    }
  } = useAppSelector((state) => state);

  const showModal = useAppSelector((state: RootState) => state.userOtp?.showModal ?? false);

  const { orderStatus, checkoutOrderDetails, loader } = useAppSelector(
    ({ checkoutOrder: { orderStatus, checkoutOrderDetails, loader } }) => ({
      orderStatus,
      checkoutOrderDetails,
      loader,
    })
  );
 
  useEffect(() => {
    dispatch(getUserDetails());
  }, []);
  const { currentLocation } = useSelector((state: RootState) => state.location);
  const { checkout } = useCheckout(checkoutOrderDetails, userDetails);
  const isLoggedIn = useAppSelector(
    ({ userOtp: { isLoggedIn } }) => isLoggedIn
  );
  const onChange = (value: string) => {
    setOtp(value);
  };
  const guestUserId = useAppSelector((state: RootState) => state?.userOtp?.userDetails?._id);
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const storeLocation = (latitude: number, longitude: number) => {
    localStorage.setItem("latitude", latitude.toString());
    localStorage.setItem("longitude", longitude.toString());
  };
  const storedLocation = getStoredLocation();
  useEffect(()=>{
    if(checkoutOrderDetails.inputAddress !== ""){
      dispatch(setCheckoutData({
        currentLocation:currentLocation,
      }))
    }else{
      dispatch(setCheckoutData({
        currentLocation:currentLocation,
        inputAddress:currentLocation
      }))
    }
  },[currentLocation])
 
  const handleCheckout =async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    const { currentLocation, inputAddress } = checkoutOrderDetails;
   
     if(inputAddress === currentLocation){
      
      const result = await dispatch(checkLocationDistance({longitude:storedLocation?.longitude,latitude:storedLocation?.latitude})).unwrap();

    if (!result) {
      console.error("Failed to fetch location distance.");
      return;
    }

    const { data, response } = result;
      
      if (data.status === false 
        && response?.status === 200
      ) {
        dispatch(
          setToastMessage({
            type: toastMessages.TYPE.ERROR,
            message: toastMessages.MESSAGE.OUTSIDE_LOCATION,
            show: true,
          })
        );
       return 
       
      }
     }
    if ((isLoggedIn && !userDetails.guest) || isPhoneNumberVerified) {
      checkout();
      dispatch(setSelectedAddress(""))
      dispatch(setFinalSelectedAddress(""))
    } else {
      dispatch(sendOtp(`+91${checkoutOrderDetails.contactNumber}`))
    }
  };
 
 

  const handleResyncLocation = () => {
    console.log("Resyncing location...");

    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      const storedLocation = getStoredLocation();
  
      if (
        latitude !== storedLocation.latitude ||
        longitude !== storedLocation.longitude || 
        !storedLocation.currentAdress
      ) {
        dispatch(fetchCurrentLocation({ latitude, longitude }));

        storeLocation(latitude, longitude);
      } 
    });
  };

  const mergeCartToExistingUser = () => {
    const params = {
      phoneNumber: `+91${checkoutOrderDetails.contactNumber }`,
      guestUserId,
    };
    dispatch(mergeUserProductFromCart(params));  
  };

  const guestUserContinue = ()=> {
    dispatch(setMessage(responseMessages.CONTINUE_AS_GUEST_USER));
  }

  const handleResendOtp= ()=>{    
    dispatch(sendOtp(`+91${checkoutOrderDetails.contactNumber}`))
  } 

  useEffect(()=>{
    if ( message === responseMessages.OTP_SENT ) {
      setOtpScreen(true)
    }
    if ( message === responseMessages.USER_ALLREADY_EXIST ||
         message === responseMessages.PHONE_NUMBER_VERIFIED ) {
      setOtpScreen(false);
    }
    if ( message === responseMessages.USER_ALLREADY_EXIST ) {
      setSignInOptions(true)  
    }
    if ( message === responseMessages.CART_MERGED_SUCCESSFULLY ) {
      setSignInOptions(false)  
    }
    if (message === responseMessages.PHONE_NUMBER_VERIFIED){
      setIsPhoneNumberVerified(true)
    }
    if (message === responseMessages.CONTINUE_AS_GUEST_USER) {
      setSignInOptions(false) 
      setIsPhoneNumberVerified(true)
    }
  },[message])

  useEffect(() => {
    if (otp.length === 6) {
      const params = {
        phoneNumber: `+91${checkoutOrderDetails.contactNumber}`,
        otp,
        guestUserId,
      };
      dispatch(verifyUserContact(params));
      setOtp("")  
    }
  }, [otp]);  
  
  return (
    <>
    <form onSubmit={handleCheckout}>
      {/* {orderStatus && (
        <div className="isOrderPlaced">
          <OrderPlacedMessage />
        </div>
      )} */}

      {currentLocation || checkoutOrderDetails?.inputAddress ? (
        <div className={`place-order-button ${loader ? "disabled" : ""}`}>
          {loader && <LoadingSpinner spinner={true} />}
          <div className="place-order-address">
            <div className="place-order-address-section">
              <div className="place-order-address-left">
                <img
                  className="place-order-address-img"
                  src="/Images/pin.svg"
                />
                <div className="place-order-address-content">
                  <div className="place-order-address-content-header">
                    Delivering to Home
                  </div>
                  <div className="place-order-address-content-bottom">
                    {checkoutOrderDetails.inputAddress
                      ? checkoutOrderDetails.inputAddress
                      : currentLocation}
                  </div>
                </div>
              </div>
              <div className="place-order-address-right">
                <div
                  className="place-order-address-right-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setDrawerOpen(true);
                  }}
                >
                  Change
                </div>
              </div>
            </div>
            <div className="place-order-submit-container">
            <button disabled={loader} className="place-order-submit">
                {((isLoggedIn && !userDetails.guest) || isPhoneNumberVerified) ? "Place Order" : "Verify Contact Number"}
              </button>
            </div>
          </div>
          <AddressDrawer
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            handleResyncLocation={handleResyncLocation}
          />
        </div>
      ) : (
        <div className={`place-order-button`}>
          <div className="place-order-address">
            <div className="place-order-submit-container">
              <div className="place-order-shimmer-content">
                <Shimmer className="place-order-shimmer-image" />
                <Shimmer className="place-order-shimmer-address" />
              </div>
            </div>

            <div className="place-order-submit-container">
              <div
                className="place-order-submit"
                onClick={() => setDrawerOpen(true)}
              >
                <div className="">{"Add Address"}</div>
              </div>
            </div>
          </div>
          <AddressDrawer
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            handleResyncLocation={handleResyncLocation}
          />
        </div>
      )}
    </form>
      <ReactPortal>
        <Modal showModal={otpScreen}>
          <OtpVerification
            isLoading={isLoading}
            number={checkoutOrderDetails.contactNumber}
            onChange={onChange}
            otp={otp}
            onSignup={async () =>handleResendOtp()}/>
        </Modal>
      </ReactPortal>
      <Modal showModal={signInOptions}>      
        <SignInOptionsModal
          onMerge={mergeCartToExistingUser}
          onGuestContinue={guestUserContinue}
          />
      </Modal>
    </>
  );
};

export { CheckoutForm };
