import { useEffect, useState, useRef } from "react";
import {
  Address,
  UserDetailsInterface,
} from "../../appState/componentSlices/userSlice/@types/User.Initerface";
import {
  getUserDetails,
  updateUserDetails,
  updateUserImage
} from "../../appState/componentSlices/userSlice/userLoginSlice";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { Accordion, LoadingSpinner } from "../../components";
import "./Profile.scss";
import { batch } from "react-redux";
import {
  setDisableFooter,
  setTitle,
  setToastMessage,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";
import { useNavigate } from "react-router";
import Image from "../../components/image/Image";
import {ButtonLoader} from "../../components/buttonLoader/ButtonLoader";
import { toastMessages } from "../../constant/toastMessage";
function Profile() {
  const userDetails: UserDetailsInterface = useAppSelector(
    (state) => state.userOtp.userDetails
  );
  const loading= useAppSelector(
    (state) => state.userOtp.loading
  );
  const dispatch = useAppDispatch();
  const { phone, name = "Not Set", addresses, image } = userDetails;
  const navigate = useNavigate();

  useEffect(() => {
    batch(() => {
      dispatch(setTitle(headers.profile));
      dispatch(getUserDetails());
      dispatch(setDisableFooter(false));
    });
  }, []);
  const [isEditing, setIsEditing] = useState(false);
  const [userName, setUserName] = useState(name);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [uploadLoader , setUploadLoader ] = useState(false)
  const imageSizeLimitInMb = 5 * 1024 * 1024;

  useEffect(() => {
    setUserName(name);
  }, [userDetails]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleUpdateClick = () => {
    setIsEditing(false);
    // dispatch thunk for updating user details
    dispatch(
      updateUserDetails({
        name: userName,
      })
    );
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const blobUrl = URL.createObjectURL(file);
      if (file.size <= imageSizeLimitInMb) {
        setSelectedFile(file);
        setPreviewUrl(blobUrl);
      } else {
        dispatch(
          setToastMessage({
            type: toastMessages.TYPE.ERROR,
            message: toastMessages.MESSAGE.MAX_SIZE,
            show: true,
          })
        );
      }
    }
  };
  
  const handleUpload = async ()=>{
    if (!selectedFile) {
      return;
    }   
    setUploadLoader(true)  
    const formData = new FormData();
    formData.append('userImage', selectedFile);
    const response = await dispatch(updateUserImage(formData));    
    response && setPreviewUrl(null) 
    setUploadLoader(false)    
  }
  return(
    loading ? 
    <LoadingSpinner />
    : (
      <div className="profile-container">
      <div className="profile-title">
        <div 
          className="profile-image-container" 
          >
            <div className="profile-image-cover">
              {previewUrl 
                ?<Image 
                src={previewUrl} 
                alt="Preview" 
                className="profile-image"
                />
                :(
                  image?.secureUrl
                  ?<Image
                  src={ image.secureUrl }
                  className="profile-image"
                  />
                  :String(phone)[0]?.toUpperCase()
                )
              }
            </div>
            <div className="add-upload-image-button"
            >
              {!previewUrl
              ? <label
                  className="button-cover"
                  htmlFor="imageFIleInput"              
                >
                  <Image 
                    className="button-cover-icon"
                    src="/Images/image-select.svg" 
                    alt="" 
                  />
                </label>
              : (uploadLoader
              ? <div className="button-cover">
                  <ButtonLoader/>                
                </div>
              :<div className="delete-upload-button-cover">
                <div className="button-cover">
                  <Image 
                    className="button-cover-icon"
                    onClick={()=>setPreviewUrl(null)}                       
                    src="/Images/image-delete.svg" 
                    alt="" 
                  /> 
                </div>
                <div
                  className="button-cover"  
                >
                  <Image 
                    className="button-cover-icon"
                    onClick={handleUpload}                       
                    src="/Images/image-upload.svg" 
                    alt="" 
                  />                 
                </div>
              </div>)
              }
          </div>

          <input className= "image-file-input" id="imageFIleInput" type="file" accept="image/*" onChange={handleImageChange} />
        </div>
        <h3>Welcome {phone}</h3>
      </div>
      <div className="profile-info">
        <div>
          <div>
            <p>Name</p>
            {isEditing ? (
              <div className="profile-name-container">
                <input
                  type="text"
                  className="edit-profile profile-name"
                  value={userName}
                  onChange={handleNameChange}
                />
                <button className="btn edit-btn" onClick={handleUpdateClick}>
                  Update
                </button>
              </div>
            ) : (
              <div className="profile-name-container">
                <p className="profile-name">{userName}</p>
                <button className="btn edit-btn" onClick={handleEditClick}>
                  Edit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="profile-address">
        <Accordion heading="Shipping Address" showDetails={true}>
          {addresses?.length ? (
            addresses.map((userAddress: Address, index: number) => {
              const {
                city = "Not Set",
                address = "Not Set",
                landmark = "Not Set",
              } = userAddress;
              return (
                <div className="address-container" key={index}>
                  <div className="location-svg">
                    <img
                      src="Images/location.svg"
                      alt="location"
                      width={20}
                      height={20}
                    />
                  </div>
                  <div className="address-info">
                    <p>City : {city}</p>
                    <p>Address: {address}</p>
                    <p>Landmark: {landmark}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p>You don't have any address </p>
          )}
        </Accordion>
      </div>
      <div className="new-arrivals"></div>
      <button className="delete-account-button" onClick={()=>navigate("/account-deletion")}>
        Delete Account
      </button>
    </div>
  )
)
}
export { Profile };
