import React from "react";
import { DiscountModalProps } from "../../typeDefination";
import { generateConfetti } from "../../utils/generateConfetti";

export const DiscountModal: React.FC<DiscountModalProps> = ({
  title,
  discount,
  onClose,
  description,
}: DiscountModalProps) => {

  return (
    <div className="discount-modal-overlay" onClick={onClose}>
      <div className="confetti-container">{generateConfetti()}</div>
      <div className="discount-modal-container">
        <div
          className="discount-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="discount-title">{title}</h1>
          {discount && <p className="discount-amount">{discount}</p>}
          {description && (
            <p className="discount-modal-description">{description}</p>
          )}
        </div>
      </div>
    </div>
  );
};
