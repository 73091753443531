export const toastMessages = {
  TYPE: {
    ERROR: "error",
    SUCCESS: "success",
  },
  MESSAGE: {
    SIGNED_OUT: "Signed out successfully",
    REQUIRED_FIELDS: "Please fill all required fields",
    ORDER_SUCCESS: "Order placed successfully",
    ORDER_FAIL: "Unable to place order",
    CUSTOMER_LOCATION_SUCCESS: "Location Added Successfully",
    CUSTOMER_LOCATION_FAILED:
      "Failed to get Location, Allow chrome notification",
    OTP_SUCCESS: "Otp send successfully",
    OTP_FAIL: "Error while sending OTP, refresh the page and try again",
    LOGIN_SUCCESS: "Login Successfull",
    LOGIN_FAIL: "Login Failed, refresh the page and try again",
    MAX_SIZE: "The Image size exceeds the 5 MB limit. Please select a smaller image and try again.",
    OUTSIDE_LOCATION: "This location is outside from 50 km range. Please change the location",
    LOCATION_ACCESS_GRANTED:"Location access granted.",
    LOCATION_ACCESS_BLOCKED:"Location access is blocked. Please enable location permissions in your device settings to proceed",
    UNABLE_TO_CHECK_LOCATION:"Unable to check location permission. It seems your system does not support location sharing."

  },
};
