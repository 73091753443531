import { ItemCart } from "../itemCart/ItemCart";



interface CartsProductsAccordionProps {
  cart: any;
}

const CartProductsAccordion = ({ cart }: CartsProductsAccordionProps) => {
  
  return (
    <>
        <div>
        {cart.productInCart?.products &&
          cart.productInCart.products.map((item: any) => (
            <ItemCart key={item.id} {...item} />
          ))}
        </div>
    </>
  );
};

export { CartProductsAccordion };
