import { useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import {
  Product,
  getAllProductsByCategory,
} from "../../appState/componentSlices/allProductsFeed";
import {
  Card,
  ErrorMessage,
  Footer,
  NavBar,

  ProductCard,
} from "../../components";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { errorMessages } from "../../constant/errorMessages";
import { batch } from "react-redux";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { RootState } from "../../appState/store";
const icon = "/Images/emptyCart.svg";

const ProductList = () => {
  // find category from url path
  const { category, id } = useParams();
  const disptach = useAppDispatch();
  const allProducts = useAppSelector(
    (state) => state.ProductsData.allProductsByCategory
  );
  const loader = useAppSelector((state) => state.ProductsData.plpLoader);
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error :(</p>;
  const searchedProducts = useAppSelector((state: RootState) => state.ProductsData.searchedProducts || []);
  const isSearch = useAppSelector((state: RootState) => state.search.isSearch);
  
  useEffect(() => {
    batch(() => {
      disptach(setTitle(category));
      disptach(setDisableFooter(false));
      disptach(
        getAllProductsByCategory({
          productBody: {
            groupBy: id === "brands" ? "itemBrandName" : "itemCategory",
            matchByField: {
              _id: category,
            },
          },
        })
      );
    });
  }, [category]);
  return (
    <div>
      <div className="products-container-main">
        <div className="products-container">
          {!loader ? (
            isSearch ? (
              searchedProducts.data.length > 0
                ? searchedProducts.data.map((product: any, index: number) => (
                  <Card key={index} style={{maxWidth: '120px'}}>
                    <ProductCard product={product} />
                  </Card>
                ))
                : <div
                  className="no-result-search-message"
                >
                  {searchedProducts.message}
                </div>
            ) : allProducts[0]?.products && Array.isArray(allProducts[0]?.products) ? (
              allProducts[0].products.map((product: Product, index: number) => (
                <Card key={index} style={{maxWidth: '120px'}}>
                  <ProductCard product={product} />
                </Card>
              ))
            ) : (
              <div className="no-products-container">
                <ErrorMessage
                  icon={icon}
                  message={errorMessages.PRODUCT.NO_PRODUCT}
                />
              </div>
            )
          ) : (
            <div className="spinner-container-main">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );

};

export { ProductList };
