import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import {
  CartProductsAccordion,
  DiscountModal,
  ErrorMessage,
  LoadingSpinner,
  OrderSummary,
  PaymentAccordion,
} from "../../components";
import { CheckoutForm } from "../../components/form/CheckoutForm";

import useCart from "../../hooks/useCart";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";
import { errorMessages } from "../../constant/errorMessages";
import { batch } from "react-redux";
import { setCheckoutData } from "../../appState/componentSlices/checkoutSlice/checkoutSlice";
import { discountModalMessages, localStorageKeys } from "../../constant/discountModalMessages";
const icon = "/Images/emptyCart.svg";

const CheckoutPage = () => {
  const { cart } = useCart();
  const [showDiscountModal, setDiscountModal] = useState(false);
  const [discountTitle, setDiscountTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false); 
  const {
    productInCart: { length: cartLength = 0 },
  } = cart;
  const dispatch = useAppDispatch();
  const { checkoutOrderDetails, status } = useAppSelector(
    ({
      checkoutOrder: { checkoutOrderDetails },
      productInCart: { status },
    }) => ({
      checkoutOrderDetails,
      status,
    })
  );

  const {
    userOtp: {
      userDetails,
      isLoggedIn,

    }
  } = useAppSelector((state) => state);

  const handleContactNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length <= 10) {
      dispatch(
        setCheckoutData({ contactNumber: value })
      );
    }
  };

  useEffect(() => {
    batch(() => {
      dispatch(setDisableFooter(cartLength > 0));
      dispatch(setTitle(headers.checkout));
    });
  }, [cartLength]);

  useEffect(() => {
    if (cartLength === 0 && status === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [cartLength, status]);

  useEffect(() => {
    const deliveryCharge = cart?.productInCart?.deliveryCharge;
    const discountAmount = cart?.productInCart?.discountAmount;
    const hasFreeDeliveryBeenShown = localStorage.getItem(
      localStorageKeys.freeDeliveryShown
    );
    const hasDiscountAmountBeenShown = localStorage.getItem(
      localStorageKeys.discountAmountShown
    );
    const storedDiscountAmount = localStorage.getItem(
      localStorageKeys.storedDiscountAmount
    );

    if (discountAmount !== parseFloat(storedDiscountAmount || "0")) {
      if (discountAmount === 0) {
        localStorage.removeItem(localStorageKeys.discountAmountShown);
        localStorage.removeItem(localStorageKeys.storedDiscountAmount);
      }
      localStorage.setItem(localStorageKeys.storedDiscountAmount, discountAmount);
      if (discountAmount > parseFloat(storedDiscountAmount || "0")) {
        setDiscountTitle(discountModalMessages.discountApplied(discountAmount));
        setDiscountModal(true);
        localStorage.setItem(localStorageKeys.discountAmountShown, "true");
      }
    }

    if (deliveryCharge === 0 && !hasFreeDeliveryBeenShown) {
      setDiscountTitle(discountModalMessages.freeDelivery);
      setDiscountModal(true);
      localStorage.setItem(localStorageKeys.freeDeliveryShown, "true");
    }

    if (deliveryCharge > 0) {
      localStorage.removeItem(localStorageKeys.freeDeliveryShown);
    }

    if (
      deliveryCharge === 0 &&
      discountAmount > 0 &&
      !hasFreeDeliveryBeenShown &&
      !hasDiscountAmountBeenShown
    ) {
      setDiscountTitle(discountModalMessages.freeDeliveryWithDiscount(discountAmount));
      setDiscountModal(true);
      localStorage.setItem(localStorageKeys.freeDeliveryShown, "true");
      localStorage.setItem(localStorageKeys.discountAmountShown, "true");
    }
  }, [cart]);
  
  if (userDetails.phone) {
    dispatch(
      setCheckoutData({ contactNumber: userDetails.phone })
    );
  }

  return (
    <div className="checkout-page-container">
      <div className="main-content">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
              {cartLength ? (
                <>
                  {showDiscountModal && (
                    <DiscountModal
                      onClose={() => setDiscountModal(false)}
                      title={discountTitle}
                    />
                  )}
                  <div className="checkout-page-products">
                    <CartProductsAccordion cart={cart} />
                  </div>
                  <div className="order-summary">
                    <OrderSummary cart={cart} />
                  </div>
                  <div className="cancellation-policy-container">
                    <div className="cancellation-policy-container-heading">
                      Cancellation Policy
                    </div>
                    <div className="cancellation-policy-container-text">
                      Orders cannot be cancelled once packed for delivery. In
                      case of unexpected delays, a refund will be provided, if
                      applicable.
                    </div>
                  </div>
                  <div className="payment-method-container">
                    <div className="payment-method">
                      <div className="payment-method-heading">
                        Payment Method
                      </div>
                      <div className="payment-method-accordion">
                        <PaymentAccordion
                          checkoutData={checkoutOrderDetails}
                          setCheckoutData={setCheckoutData}
                        />
                      </div>
                    </div>
                  </div>
                  {!(isLoggedIn && !userDetails.guest) &&
                  <div className="payment-contactNumber-container">
                    <div className="payment-contactNumber">
                      <div className="payment-contactNumber-heading">Phone Number</div>
                      <div className="payment-contactNumber-input-container">
                        <p className="payment-contactNumber-text">+91 </p>
                        <input
                          className="payment-contactNumber-input"
                          type="number"
                          value={checkoutOrderDetails.contactNumber}
                          onChange={(event) => {
                            handleContactNumber(event)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  }
                  <CheckoutForm />
                </>
              ) : (
                <ErrorMessage
                  icon={icon}
                  message={errorMessages.CART.NO_PRODUCT}
                />
              )}
            </>
        )}
      </div>
    </div>
  );
};

export { CheckoutPage };
