import { useEffect, useState } from "react";
import { CartQuantityButton } from "../cartQuantityButton/CartQuantityButton";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import useCart from "../../hooks/useCart";
import { setProductIdPDP } from "../../appState/componentSlices/allProductsFeed";
import { useNavigate } from "react-router";
const ItemCart = (item: any) => {
  const [productQuantity, setProductQuantity] = useState(item.productQuantity);
  const cartLength = useAppSelector(
    (state) => state.productInCart.productInCart.length
  );

  const { removeFromCart } = useCart();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    productDetails: {
      itemSellingPricePerUnit = 0,
      itemName = "",
      itemMRPperUnit = 0,
      images = [],
      _id: productId = ""
    } = {},
  } = item;

  const openProductDescriptionPage = () => {
    if(productId) {
      dispatch(setProductIdPDP(productId));
      navigate("/product-description", { state: { productId }});
    }
  }

  const [mrp, setMrp] = useState(itemSellingPricePerUnit);
  const [itemCopy, setItemCopy] = useState<any>(structuredClone(item));
  const { slabPricing = [] } = itemCopy?.productDetails;
  // mrp set when quantity changes with respect to slab
  useEffect(() => {
    let index = 0;
    for (const slab of slabPricing) {
      if (
        productQuantity <= slabPricing[index + 1]?.[1] - 1 ||
        !slabPricing[index + 1]
      ) {
        setItemCopy((prev: { productDetails: any }): any => {
          prev.productDetails.itemSellingPricePerUnit = slab[2];
          return prev;
        });
        setMrp(slab[2]);
        break;
      }
      index++;
    }
  }, [productQuantity]);
  useEffect(() => {
    setMrp(itemSellingPricePerUnit);
  }, [cartLength, itemSellingPricePerUnit]);

  return (
    <>
      <div className="cart-product-container">
        <div className="cart-item-product">
          {/* <button className="cart-item-remove">
        <img
          src="Images/delete.svg"
          alt=""
          onClick={() => removeFromCart({product: item, quantity: - productQuantity})}
        />
      </button> */}
          <div className="cart-product-image">
            <img
              className="cart-image-product"
              src={images?.[0]?.secureUrl || "/default-image.jpg"}
              alt={itemName || "Product Image"}
            />
          </div>

          <div
            className="cart-product-content"
            onClick={openProductDescriptionPage}
          >
            <div className="cart-product-content-text">
              <div className="cart-item-name">
                {itemName?.length > 20
                  ? itemName.slice(0, 18) + "..."
                  : itemName}
              </div>
              <div className="cart-item-info">
                {itemMRPperUnit > itemSellingPricePerUnit ? (
                  <>
                    <del className="mrp-price product-mrp">
                      &#x20B9;{itemMRPperUnit}
                    </del>
                    <span className="cart-item-sp">
                      &#x20B9;{itemSellingPricePerUnit}
                    </span>
                  </>
                ) : (
                  <span className="cart-item-sp">
                    &#x20B9;{itemSellingPricePerUnit}
                  </span>
                )}
              </div>
            </div>

            <div className="cart-product-content-button">
              <CartQuantityButton
                quantity={productQuantity}
                showAddButton={false}
                product={itemCopy}
                setProductQuantity={setProductQuantity}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ItemCart };
