

export const parseDeliveryTime = (timeStr: string): number => {
    const timeParts = timeStr.split(" ");
    let totalSeconds = 0;
  
    for (let i = 0; i < timeParts.length; i += 2) {
      const value = parseInt(timeParts[i], 10); 
      const unit = timeParts[i + 1]?.toLowerCase(); 
  
      if (unit.includes("hour")) {
        totalSeconds += value * 3600; 
      } else if (unit.includes("min")) {
        totalSeconds += value * 60; 
      }
    }
  
    return totalSeconds;
  };
  