import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "../../appState/reduxTypedHooks";
import { SpeechRecognitionUtility } from "../speechToTextComponent/speechToTextComponent";

interface SearchBarProps {  
  setQuery: (newValue: string) => void;
  query: string;
  type?: string;
  _id?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({setQuery, query, type, _id }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchedProducts = useAppSelector((state) => state.ProductsData.searchedProducts || []);
  const onSubmit = (e: React.MouseEvent<HTMLFormElement, MouseEvent>) => {
    e.preventDefault();
    const isSearchPage = pathname === "/search-products",
     hasValidTypeAndId = type && _id,
     isMissingTypeOrId = !type && !_id;
    if(!isSearchPage && (isMissingTypeOrId || hasValidTypeAndId) ) {
      navigate("/search-products", { state: { searchedProducts } });
    } 
  }

  return (
    <div className="search-input-container">
      <div className="search-input-text-icon-container">
      <img height="20vh" src="Images/search-line.svg" alt="" />
      <form onSubmit={onSubmit}>
        <input
          className="search-input-element"
          placeholder="Search"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
        />
      </form>
      </div>
      <SpeechRecognitionUtility setQuery= {setQuery}/>
    </div>
  );
};

export { SearchBar };
