import { configureStore } from "@reduxjs/toolkit";
import { AllItemsSlice } from "./componentSlices/allItemsFeed.slice";
import { AllProductsSlice } from "./componentSlices/allProductsFeed";
import { productInCartSlice } from "./componentSlices/cartSlice/ProductInCartSlice";
import { CheckoutOrderSlice } from "./componentSlices/checkoutSlice/checkoutSlice";
import { OrderSlice } from "./componentSlices/orderSlice/OrdersSlice";
import { userLoginSlice } from "./componentSlices/userSlice/userLoginSlice";
import { utilSlice } from "./componentSlices/utilSlice/utilSlice";
import {tokenSlice} from './componentSlices/tokenSlice';
import {searchSlice} from './componentSlices/searchslice';
import locationSlice from './componentSlices/locationSlice/locationSlice'; 
import {addressSearchSlice} from './componentSlices/addressSearchSlice/addressSearchSlice'; 
export const store = configureStore({
  reducer: {
    ItemsData: AllItemsSlice.reducer,
    ProductsData: AllProductsSlice.reducer,
    productInCart: productInCartSlice.reducer,
    checkoutOrder: CheckoutOrderSlice.reducer,
    allUserOrders: OrderSlice.reducer,
    orderStatus: OrderSlice.reducer,
    userOtp: userLoginSlice.reducer,
    util: utilSlice.reducer,
    token: tokenSlice.reducer,
    search: searchSlice.reducer,
    location: locationSlice,
    addressSearch: addressSearchSlice.reducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
