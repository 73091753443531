import { createPaymentOrder } from "../appState/componentSlices/checkoutSlice/createPaymentOrder";
import { updateOrderPayment } from "../appState/componentSlices/checkoutSlice/updateOrderPayment";
import loadRazorpay  from "./loadRazorpay"; // Ensure this utility loads Razorpay SDK


export const initiateRazorpayPayment = async ({
  amount,
  orderId,
  key,
  userDetails,
  dispatch,
  navigate,
  themeColor = "#27ae60",
  isUpiDuePayment= false
}:any) => {
  try {
    // Load Razorpay SDK
    const razorpayLoaded = await loadRazorpay();
    if (!razorpayLoaded) {
      alert("Razorpay SDK failed to load. Please check your internet connection.");
      return;
    }

    // Create a payment order
    const paymentOrder = await dispatch(
      createPaymentOrder({
        amount: amount * 100, // Convert amount to paise
        currency: "INR",
        orderId,
        isUpiDuePayment,
      })
    ).unwrap();

    const { amount: orderAmount, id: razorpayOrderId, currency } = paymentOrder;

    // Configure Razorpay options
    const options = {
      key,
      amount: orderAmount.toString(),
      currency,
      name: "Priyam Stores",
      description: "Order Payment",
      order_id: razorpayOrderId,
      handler: async (res: any) => {
        try {
          const paymentResult = {
            razorpayPaymentId: res.razorpay_payment_id,
            razorpayOrderId: res.razorpay_order_id,
            razorpaySignature: res.razorpay_signature,
            orderId,
          };

          // Dispatch updateOrderPayment thunk
          await dispatch(updateOrderPayment(paymentResult));

          // Optionally, navigate to success page
          navigate && navigate(`/payment-success`);
        } catch (error) {
          console.error(error);
          alert("Payment verification failed. Please contact support.");
        }
      },
      prefill: {
        name: userDetails.name || "",
        email: userDetails.email || "",
        contact: userDetails.contact || "",
      },
      theme: {
        color: themeColor,
      },
    };

    // Initialize Razorpay and open the payment window
    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  } catch (error) {
    console.error(error);
    alert("Error during payment processing. Please try again.");
  }
};
