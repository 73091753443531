import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SearchAddress, Shimmer } from "../../components";
import { RootState } from "../../appState/store";
import { setLandmark } from "../../appState/componentSlices/addressSearchSlice/addressSearchSlice";
import { setCheckoutData } from "../../appState/componentSlices/checkoutSlice/checkoutSlice";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../appState/reduxTypedHooks";

const AddAddress = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const finalSelectedAddress = useSelector(
    (state: RootState) => state?.addressSearch?.finalSelectedAddress
  );
  const landmark = useSelector(
    (state: RootState) => state?.addressSearch?.landmark
  );
  const outsideRangeMessage = useAppSelector(
    (state) => state.addressSearch.outsideRangeMessage
  );
  
  const {checkoutOrderDetails} = useAppSelector(
    ({ checkoutOrder: {  checkoutOrderDetails } }) => ({
      checkoutOrderDetails,
    })
  );
  const [landmarkInput, setLandmarkInput] = useState(landmark || "");

  const handleLandmarkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLandmarkInput(value);
    dispatch(setLandmark(value));
  };
  
  const address =
    finalSelectedAddress?.address ||
    checkoutOrderDetails.inputAddress ||
    checkoutOrderDetails.currentLocation;
  

 
 const handleOnClick = () => {
   dispatch(
     setCheckoutData({
       inputAddress: finalSelectedAddress?.address,
       addressLandmark: landmarkInput,
     })
   );
   navigate(-1)
 };
  return (
    <>
      <SearchAddress />

      <div className="confirm-address-container">
        {address ? (
          <h3 className="confirm-address-container-h3">Delivering your order to</h3>
        ) : (
          <h3 className="confirm-address-container-h3">Getting your pin location...</h3>
        )}
        {address  && !outsideRangeMessage ? (
          <div className="selected-address-container">
            <img
              width="44"
              height="44"
              src="https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=225/layout-engine/2024-01/image.png"
              alt="Address Icon"
            />
            <div className="selected-address">
              {address}
            </div>
          </div>
        ) : (
          <div className="shimmer-content">
            <Shimmer className="shimmer-image" />
            <Shimmer className="shimmer-address" />
          </div>
        )}
        {address && !outsideRangeMessage ? (
          <div className="landmark-container">
            <label htmlFor="landmark" className="landmark-label">
              Landmark
            </label>
            <input
              type="text"
              id="landmark"
              value={landmarkInput}
              onChange={handleLandmarkChange}
              placeholder="Add a nearby landmark"
              className="landmark-input"
            />
          </div>
        ) : (
          <Shimmer className="shimmer-button" />
        )}
        {address && !outsideRangeMessage ? (
          <div className="confirm-button" onClick={handleOnClick}>Confirm location & proceed</div>
        ) : (
          <Shimmer className="shimmer-button" />
        )}
      </div>
    </>
  );
};

export { AddAddress };
