import { useNavigate } from "react-router";
import {
  getCheckoutData,
  setLoader,
  setOrderStatus,
  // createPaymentOrder,
  // updateOrderPayment,
} from "../appState/componentSlices/checkoutSlice/checkoutSlice";
import { useAppDispatch } from "../appState/reduxTypedHooks";
import useCart from "./useCart";
import { getProductInCart } from "../appState/componentSlices/cartSlice/ProductInCartSlice";
import { batch } from "react-redux";
import loadRazorpay from "../utils/loadRazorpay";
import getCookie from "../utils/getCookie";
import { createPaymentOrder } from "../appState/componentSlices/checkoutSlice/createPaymentOrder";
import { updateOrderPayment } from "../appState/componentSlices/checkoutSlice/updateOrderPayment";
import { localStorageKeys } from "../constant/discountModalMessages";

const useCheckout = (checkoutData: any, userDetails: any) => {
  const { cart } = useCart();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const checkout = () => {
    batch(async () => {
      const {
        inputAddress,
        currentLocation,
        contactNumber = userDetails?.phone,
        paymentMethod,
        preferredDeliverySlot,
        addressLandmark,
        city = "",
        selectedAddressId,
        isUpiDuePayment = false,
      } = checkoutData;

      // Set loader to true
      await dispatch(setLoader(true));

      // Prepare checkout data
      const data = {
        shippingAddress: {
          address: inputAddress.trim(),
          currentLocation: currentLocation,
          landmark: addressLandmark.trim(),
          city: city,
          contactNumber,
          addressId: selectedAddressId,
        },
        paymentMethod: paymentMethod,
        timeSlot:
          preferredDeliverySlot + " " + new Date().toLocaleDateString(),
        cart: cart,
      };

      // Dispatch getCheckoutData thunk
      const response = await dispatch(getCheckoutData({ data }));


      if (paymentMethod !== "cash") {
        try {
          const token = getCookie("token");

          // Load Razorpay script
          const res = await loadRazorpay();
          if (!res) {
            alert(
              "Razorpay SDK failed to load. Please check your internet connection."
            );
            return;
          }

          // Dispatch createPaymentOrder thunk
          const paymentOrder = await dispatch(
            createPaymentOrder({
              amount: response.payload.data.totalPayableAmount  * 100, // Convert amount to paise
              currency: "INR",
              orderId: response?.payload?.data?._id,
              isUpiDuePayment,
            })
          ).unwrap();

          const { amount, id: razorpayOrderId, currency } = paymentOrder;

          // Configure Razorpay options
          const options = {
            key: process.env.REACT_RAZORPAY_KEY,
            amount: amount.toString(),
            currency: currency,
            name: "Priyam Stores",
            description: "Order Payment",
            order_id: razorpayOrderId,
            handler: async (res: any) => {
              try {
                const paymentResult = {
                  razorpayPaymentId: res.razorpay_payment_id,
                  razorpayOrderId: res.razorpay_order_id,
                  razorpaySignature: res.razorpay_signature,
                  orderId: response?.payload?.data?._id,
                };

                // Dispatch updateOrderPayment thunk
                await dispatch(updateOrderPayment(paymentResult));

                // Optionally, navigate to success page
                // navigate('/payment-success');
              } catch (error) {
                console.error(error);
                alert("Payment verification failed. Please contact support.");
              }
            },
            prefill: {
              name: userDetails.name || "",
              email: userDetails?.email || "",
              contact: contactNumber || "", // Add user's contact number
            },
            theme: {
              color: "#27ae60",
            },
          };

          // Initialize Razorpay with the options
          const paymentObject = new (window as any).Razorpay(options);
          paymentObject.open();

          // Dispatch getProductInCart and setLoader(false)
          batch(async () => {
            await dispatch(getProductInCart(""));
            await dispatch(setLoader(false));
          });

          // Navigate to order page after two seconds
          setTimeout(async () => {
            await dispatch(setOrderStatus(false));
            navigate(`/order/${response?.payload?.data?._id}`);
          }, 2000);
        } catch (e) {
          console.log({ e });
          await dispatch(setLoader(false));
          alert("An error occurred during the payment process.");
        }
      } else {
        // For cash payments

        // Dispatch getProductInCart and setLoader(false)
        batch(async () => {
          await dispatch(getProductInCart(""));
          await dispatch(setLoader(false));
        });

        // Navigate to order page after two seconds
        setTimeout(async () => {
          await dispatch(setOrderStatus(false));
          navigate(`/order/${response?.payload?.data?._id}`);
          localStorage.removeItem(localStorageKeys.discountAmountShown);
          localStorage.removeItem(localStorageKeys.freeDeliveryShown);
          localStorage.removeItem(localStorageKeys.storedDiscountAmount);

        }, 2000);
      }
    });
  };

  return { checkout };
};

export default useCheckout;
