import React, { useState, useEffect } from 'react';

interface ImageProps {
    src: string;
    alt?: string;
    className?: string;
    imageRef?: React.Ref<HTMLImageElement>;
    onClick?: () => void;
}

const Image: React.FC<ImageProps> = ({ src, alt = '', className = '', imageRef, onClick }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const noImage = '/Images/no-image.svg';    
    const [imageSrc, setImageSrc] = useState(src ?? noImage);

    useEffect(() => {
        setImageSrc(src || noImage);
    }, [src]);

    return (
        <img
            ref={imageRef}
            src={imageSrc}
            alt={alt}
            className={`${className} ${!isLoaded ? 'lazy':""}`}
            onLoad={() => setIsLoaded(true)}
            onError={() => setImageSrc(noImage)}
            onClick={onClick}
        />
    );
};

export default Image;
