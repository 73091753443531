import { Link, useParams } from "react-router-dom";
import { CategoriesImages } from "../../constant/CategoriesImages";
import Image from "../image/Image";

const CategorySmallCard = (props: any) => {
    const { category } = props;
    const params = useParams();
    const categoryType = props.type || params.id;
     
    return (
        <Link
            className="category-small-card"
            to={"/" + `${categoryType}` + "/" + `${category?.name}`}
        >
            {
                <div
                    className="category-small-card-main"
                >
                    <div className="category-small-card">
                        <Image
                            className="category-small-card-image"
                            src={CategoriesImages[category?.name] || category?.image?.secureUrl}
                            alt=""                        
                        />
                    </div>
                    <div className="category-small-cart-item-info">
                        <div className="category-small-cart-item-name">{category?.name}</div>
                    </div>
                </div>
            }
        </Link>
    )
}

export default CategorySmallCard