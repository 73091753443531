import React from "react";

type SignInOptionsProps = {
  onMerge: () => void;
  onGuestContinue: ()=> void
};

const SignInOptionsModal = ({ onMerge, onGuestContinue }: SignInOptionsProps) => {
  return (
    <div className="signIn-options-modal-container">
      <h2 className="signIn-options-modal-head" >This number is already associated with an account.</h2>
      <p className="signIn-options-modal-text">Do you want to log in instead?</p>
      <div className="signIn-options-modal-button-container" >
        <button className="signIn-options-modal-button" onClick={onMerge}>Yes</button>
        <button className="signIn-options-modal-button" onClick={onGuestContinue}>No</button>
      </div>
    </div>
  );
};

export { SignInOptionsModal };