import React from 'react'
import { Accordion } from '../accordion/Accordion'
import { Card } from '../card/Card';
import { ProductCard } from '../productCard/ProductCard';
import { Link } from 'react-router-dom';
interface PopularAccordionProps {
    products: any;
    type: string;
    length?: number;
    subHeadingText: string
    subHeadingLink:string
}
export const PopularAccordion = (props: PopularAccordionProps) => {
    const { products, type, length = 0, subHeadingText, subHeadingLink } = props;
    return (
        <div className='popular-accordion'>
            <div className='popular-accordion-header'>
                <p className='popular-accordion-header-text' >
                    {type} <span className='popular-accordion-length' >({length})</span>
                </p>
                <Link className="popular-accordion-text-link" to={subHeadingLink}>
                    {subHeadingText}
                </Link>
            </div>
            <div className='popular-accordion-products-container'>
                {
                    products?.data?.map((product: any, index: number) => (
                        <Card key={index} style={{maxWidth: '120px'}}>
                            <ProductCard 
                            product={product}
                            isSlab={false} />
                        </Card>
                    ))
                }
            </div>
        </div>
    )
}
