import React, { useEffect, useState } from "react";
import "./RequestDeletion.scss";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import getCookie from "../../utils/getCookie";
import { logoutUser } from "../../appState/componentSlices/userSlice/userLoginSlice";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import { toastMessages } from "../../constant/toastMessage";

export const RequestDeletion = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setLoading(true);
      e.preventDefault();
      const token = getCookie("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/delete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...(token && { Authorization: token }),
          },
          body: JSON.stringify({ phone }),
        }
      );
      if (response.status === 200) {
        try {
          await dispatch(logoutUser());
          sessionStorage.clear();
          dispatch(
            setToastMessage({
              type: toastMessages.TYPE.SUCCESS,
              message: toastMessages.MESSAGE.SIGNED_OUT,
              show: true,
            })
          );
          navigate("/account-deletion-success");
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const { phone = "" } = useAppSelector((state) => state.userOtp.userDetails) ?? {};

  useEffect(() => {
    if (!phone || phone === 0) {
      navigate("/profile");
    }
  }, [phone]);

  return (
    <div className="request-deletion-container">
      <h1>Request Account Deletion</h1>
      <p>
        Use the form below to request the deletion of your account and
        associated data. Please note that this process is irreversible.
      </p>
      <form onSubmit={handleSubmit} className="deletion-form">
        <label htmlFor="phone">Registered Phone Number:</label>
        <div>{phone}</div>
        <button
          type="submit"
          className="submit-button"
          disabled={loading || !phone || phone === 0}
        >
          Submit Request
        </button>
      </form>
      <p className="policy-info">
        By submitting this form, you acknowledge that your account and data will
        be deleted in accordance with our{" "}
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </p>
    </div>
  );
};
