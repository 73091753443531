import React from "react";
import { Accordion } from "../accordion/Accordion";
import { Card } from "../card/Card";
import "./OrderSummary.scss";

interface OrderSummaryAccordionProps {
  cart: any;
}

function OrderSummary(props: OrderSummaryAccordionProps) {
  const { cart } = props;
  const { cartTotalSP, deliveryCharge, discountAmount } = cart?.productInCart;
  return (
    <div className="order-summary-container">
      <div className="order-summary-bill">
      <div className="order-summary-title">Bill details</div>
      <div className="order-summary-items-total">
        <div className="order-summary-items-total-left">
          <img
            className="order-summary-img"
            src="/Images/restaurant-menu.svg"
            alt="Bill Icon"
          />
          <div>Items total</div>
        </div>
        <div className="order-summary-items-total-right">&#x20B9;{cartTotalSP}</div>
      </div>
      <div className="order-summary-items-total">
        <div className="order-summary-items-total-left">
          <img
            className="order-summary-img"
            src="/Images/scooter.svg"
            alt="Bill Icon"
          />
          <div>Delivery charge</div>
        </div>
        <div className="order-summary-items-total-right">&#x20B9;{deliveryCharge}</div>
      </div>
      <div className="order-summary-grand-total">
        <div className="order-summary-grand-total-left">Grand total</div>
        <div className="order-summary-grand-total-right">
        &#x20B9;{cartTotalSP + deliveryCharge}{" "}
        </div>
      </div>
      </div>
     
       {discountAmount ? (
        <div className="order-summary-discount">
          <div className="order-summary-discount-left">
            Your total savings
          </div>
          <div className="order-summary-discount-right">
          &#x20B9;{discountAmount}
            </div>
          
        </div>
        
      ) : (
        ""
      )}
    </div>
  );
}

export { OrderSummary };
