import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../appState/store";
import {
  fetchAddressSuggestions,
  setQuery,
  resetState,
  setSelectedAddress,
  setFinalSelectedAddress,
  setOutsideRangeMessage,
} from "../../appState/componentSlices/addressSearchSlice/addressSearchSlice";
import useDebounce from "../../utils/debounce";
import MapComponent from "../map/Map";
import { useAppSelector } from "../../appState/reduxTypedHooks";
import { API } from "../../utils/api/apiPaths";
import { setCheckoutData } from "../../appState/componentSlices/checkoutSlice/checkoutSlice";
import { checkLocationDistance } from "../../appState/componentSlices/locationSlice/locationSlice";
import { ClipLoader } from "react-spinners";

const SearchAddress = () => {
  const dispatch = useDispatch<AppDispatch>();
  const apiKey = `${process.env.REACT_APP_OLA_API_KEY}`;
  const { query, suggestions } = useSelector(
    (state: RootState) => state.addressSearch
  );
  const { currentLocation } = useSelector((state: RootState) => state.location);
  const selectedAddress = useAppSelector(
    (state) => state?.addressSearch?.selectedAddress
  );
  const loading = useAppSelector(
    (state) => state?.addressSearch?.loading
  );
  const {checkoutOrderDetails} = useAppSelector(
    ({ checkoutOrder: {  checkoutOrderDetails } }) => ({
      checkoutOrderDetails,
    })
  );
  const outsideRangeMessage = useAppSelector(
    (state) => state.addressSearch.outsideRangeMessage
  );
  
  const [showDrawer, setShowDrawer] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 23.2510348,
    lng: 77.465958,
  });
  const fetchCoordinates = async (address: string) => {
    if (!address) return;
    try {
      const response = await fetch(
        `https://api.olamaps.io/places/v1/geocode?address=${address}&api_key=${apiKey}`
      );

      const data = await response.json();
      const firstResult = data.geocodingResults?.[0];
      if (firstResult) {
        const { lng, lat } = firstResult.geometry.location;
        setSelectedCoordinates({ lat, lng });
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  useEffect(() => {
    if (checkoutOrderDetails?.inputAddress) {
      fetchCoordinates(checkoutOrderDetails?.inputAddress);
    }
    else if(currentLocation){
      fetchCoordinates( currentLocation);
    }
  }, [currentLocation,checkoutOrderDetails?.inputAddress]);
  

  const debouncedSearch = useDebounce((searchQuery: string) => {
    if (searchQuery) {
      dispatch(
        fetchAddressSuggestions({
          query: searchQuery,
          currentlocation: currentLocation,
        })
      );
      setShowDrawer(true);
    } else {
      dispatch(resetState());
    }
  }, 500);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    dispatch(setOutsideRangeMessage(null));
    dispatch(setQuery(searchQuery));
    debouncedSearch(searchQuery);
  };

  const handleClearInput = () => {
    dispatch(resetState());
    setShowDrawer(false);
    setSelectedCoordinates({  lat: 23.2510348,
      lng:  77.465958, });
    dispatch(setOutsideRangeMessage(null));
  };

  const handleSelectAddress = async (address: {
    description: string;
    geometry: { location: { lat: number; lng: number } };
  }) => {
    dispatch(
      setSelectedAddress({
        address: address.description,
        lat: address.geometry.location.lat,
        lng: address.geometry.location.lng,
      })
    );
    dispatch(setQuery(address.description));
    setSelectedCoordinates(address.geometry.location);
  };

  useEffect(() => {
    const checkDistance = async () => {
      if (!selectedAddress) return;
      const result = await dispatch(
        checkLocationDistance({
          longitude: selectedAddress?.lng,
          latitude: selectedAddress?.lat,
        })
      ).unwrap();
      if (!result) {
        console.error("Failed to fetch location distance.");
        return;
      }

      const { data, response } = result;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/${API.CHECK_DISTANCE_WITHIN_RANGE}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              destination: {
                lng: selectedAddress.lng,
                lat: selectedAddress.lat,
              },
            }),
          }
        );

        const data = await response.json();
        if (data.status === false && response.status === 200) {
          setShowDrawer(true);
          setIsFocused(true);
          dispatch(setOutsideRangeMessage("This location is outside from 50 km range."));

          dispatch(setFinalSelectedAddress(""));
        } else {

          setOutsideRangeMessage(null);
          dispatch(setFinalSelectedAddress(selectedAddress));
          setShowDrawer(false);
        }
      } catch (error) {
        console.error("Error checking distance:", error);
      }
      if (data.status === false && response.status === 200) {
        setShowDrawer(true);
        setIsFocused(true);
        dispatch(setOutsideRangeMessage("This location is outside from 50 km range."));

        dispatch(setFinalSelectedAddress(""));
      } else {

        dispatch(setOutsideRangeMessage(null));
        dispatch(setFinalSelectedAddress(selectedAddress));
        setShowDrawer(false);
      }
    };

    checkDistance();
  }, [selectedAddress]);

  const handleInputFocus = () => {
    setIsFocused(true);
    if (!query) {
      setShowDrawer(true);
      dispatch(resetState());
    }
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  
  return (
    <div className="searchContainer">
      <MapComponent
        lat={selectedCoordinates.lat}
        lng={selectedCoordinates.lng}
        apiKey={apiKey}
      />
      <div className="searchBar">
        <div className="searchBarContainer">
          <div className="inputWrapper">
            <img
              className="searchIcon"
              src="/Images/searchIcon.svg"
              alt="search icon"
            />
            <input
              type="text"
              placeholder="Enter your address"
              value={query}
              onChange={handleSearch}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              className="searchInput"
            />
            <button
              className="clearButton"
              onClick={handleClearInput}
              aria-label="Clear input"
            >
              ✕
            </button>
          </div>
          {showDrawer && (
            <div className="addressSuggestionDrawer">
              {loading ? (
                <div className="addressSuggestionDrawerLoader">
                  <ClipLoader color="#27ae60" size={20} />
                </div>
              ) : outsideRangeMessage ? (
                <div className="noSuggestions">{outsideRangeMessage}</div>
              ) : suggestions.length > 0 ? (
                <ul className="suggestionsList">
                  {suggestions.map((suggestion: any, index) => (
                    <li
                      key={index}
                      className="suggestionItem"
                      onClick={() => handleSelectAddress(suggestion)}
                    >
                      <img
                        width="30"
                        height="30"
                        src="https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=225/layout-engine/2024-01/image.png"
                        alt="Address Icon"
                      />
                      <p className="suggestionItem-address">
                        {suggestion?.description}
                      </p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="noSuggestions">Type to search</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { SearchAddress };
