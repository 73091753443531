// src/appState/componentSlices/checkoutSlice/checkoutSlice.ts

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../utils/api/apiPaths";
import getCookie from "../../../utils/getCookie";
import { createPaymentOrder } from "./createPaymentOrder";
import { updateOrderPayment } from "./updateOrderPayment";

interface CheckoutSliceState {
  checkoutData: { [key: string]: any };
  orderStatus: boolean;
  checkoutOrderDetails: {
    userPreviousAddresses: string[];
    inputAddress: string;
    preferredDeliverySlot: string;
    contactNumber: string;
    paymentMethod: string;
    currentLocation: string;
    addressLandmark?: string;
    city: string;
    selectedAddressId?: string;
  };
  loader: boolean;
  paymentLoading: boolean;
  paymentError: string | null;
  paymentSuccess: boolean;
}

const initialState: CheckoutSliceState = {
  checkoutData: {},
  orderStatus: false,
  checkoutOrderDetails: {
    userPreviousAddresses: [],
    inputAddress: "",
    preferredDeliverySlot: `"12-02", "05-07", "12-02 Next Day"`,
    contactNumber: "",
    paymentMethod: "cash",
    currentLocation: "",
    addressLandmark: "",
    city: "",
  },
  loader: false,
  paymentLoading: false,
  paymentError: null,
  paymentSuccess: false,
};

// Create async thunk for checkout data (existing)
export const getCheckoutData = createAsyncThunk(
  "checkout/getCheckoutData",
  async (checkoutData: any, { rejectWithValue }) => {
    const token = getCookie("token");
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.CHECKOUT.CHECKOUT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(token && { Authorization: token }),
        },
        body: JSON.stringify(checkoutData),
      });
      const checkoutDataJson = await response.json();
      if (!response.ok) {
        return rejectWithValue(checkoutDataJson.message || "Failed to fetch checkout data");
      }
      return checkoutDataJson || [];
    } catch (error) {
      return rejectWithValue( "Network error");
    }
  }
);

// Initialize the slice
export const CheckoutOrderSlice = createSlice({
  name: "checkout",
  initialState: initialState,
  reducers: {
    // Set checkout data
    setCheckoutData: (state, action) => {
      if(action.payload)
      state.checkoutOrderDetails = Object.assign(state.checkoutOrderDetails, action.payload);
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setOrderStatus: (state, action) => {
      state.orderStatus = action.payload;
    },
    resetPaymentState: (state) => {
      state.paymentLoading = false;
      state.paymentError = null;
      state.paymentSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Existing checkoutData cases
      .addCase(getCheckoutData.pending, (state) => {
        state.orderStatus = false;
      })
      .addCase(getCheckoutData.fulfilled, (state, action) => {
        state.orderStatus = action?.payload?.status;
        state.checkoutData = action.payload;
      })
      .addCase(getCheckoutData.rejected, (state, action) => {
        state.orderStatus = false;
        state.checkoutData = [];
      })

      // Create Payment Order cases
      .addCase(createPaymentOrder.pending, (state) => {
        state.paymentLoading = true;
        state.paymentError = null;
        state.paymentSuccess = false;
      })
      .addCase(createPaymentOrder.fulfilled, (state, action) => {
        state.paymentLoading = false;
        state.paymentSuccess = true;
        // Optionally, store payment order details
        state.checkoutData.paymentOrder = action.payload;
      })
      .addCase(createPaymentOrder.rejected, (state, action) => {
        state.paymentLoading = false;
        state.paymentError = action.payload as string;
      })

      // Update Order Payment cases
      .addCase(updateOrderPayment.pending, (state) => {
        state.paymentLoading = true;
        state.paymentError = null;
        state.paymentSuccess = false;
      })
      .addCase(updateOrderPayment.fulfilled, (state, action) => {
        state.paymentLoading = false;
        state.paymentSuccess = true;
        // Optionally, update the order details
        state.checkoutData.updatedOrder = action.payload;
      })
      .addCase(updateOrderPayment.rejected, (state, action) => {
        state.paymentLoading = false;
        state.paymentError = action.payload as string;
      });
  },
});

export const { setCheckoutData, setLoader, setOrderStatus, resetPaymentState } =
  CheckoutOrderSlice.actions;

export default CheckoutOrderSlice.reducer;
