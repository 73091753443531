
import { createAsyncThunk } from "@reduxjs/toolkit";
import getCookie from "../../../utils/getCookie";

interface CreatePaymentOrderPayload {
  amount: number;
  currency: string;
  orderId: string;
  isUpiDuePayment: boolean;
}

export const createPaymentOrder = createAsyncThunk(
  "checkout/createPaymentOrder",
  async (payload: CreatePaymentOrderPayload, { rejectWithValue }) => {
    const token = getCookie("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/payments/create-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...(token && { Authorization: token }),
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "Failed to create payment order");
      }

      const data = await response.json();
      return data; // Assuming the response contains { amount, id, currency }
    } catch (error) {
      return rejectWithValue("Network error");
    }
  }
);
