import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setCheckoutData } from "../../appState/componentSlices/checkoutSlice/checkoutSlice";
import { Address } from "../../appState/componentSlices/userSlice/@types/User.Initerface";
import { useAppSelector } from "../../appState/reduxTypedHooks";
import Drawer from "../drawer/Drawer";
import { useSelector } from "react-redux";
import { RootState } from "../../appState/store";
import { useNavigate } from "react-router";
import {  setSelectedAddress } from "../../appState/componentSlices/addressSearchSlice/addressSearchSlice";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import { toastMessages } from "../../constant/toastMessage";


const AddressDrawer = ({ open, onClose, handleResyncLocation }: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isTopShadow, setIsTopShadow] = useState(false);
  const [isBottomShadow, setIsBottomShadow] = useState(false);
  const [isCurrentLocationSelected, setIsCurrentLocationSelected] = useState(false);
  const [isLocationEnabled, setIsLocationEnabled] = useState(false); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const addresses: Address[] = useAppSelector(
    (state) => state.userOtp.userDetails?.addresses
  );
  const username: string = useAppSelector(
    (state) => state.userOtp.userDetails?.name
  );
  const userPhoneNumber: number = useAppSelector(
    (state) => state.userOtp.userDetails?.phone
  );
  const selectedAddressId = useAppSelector(
    (state) => state.checkoutOrder.checkoutOrderDetails?.selectedAddressId
  );
  const { currentLocation } = useSelector((state: RootState) => state.location);

  const checkLocationEnabled = () => {
    navigator.geolocation.getCurrentPosition(
      () => {
        setIsLocationEnabled(true);
      },
      (error) => {
        console.error("Location access error:", error);
        setIsLocationEnabled(false);
      }
    );
  };

  

  const handleEnableLocation = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
  
      if (permissionStatus.state === "granted") {
        navigator.geolocation.getCurrentPosition(
          () => {
            dispatch(
              setToastMessage({
                type: toastMessages.TYPE.SUCCESS,
                message: toastMessages.MESSAGE.LOCATION_ACCESS_GRANTED,
                show: true,
              })
            );
            setIsLocationEnabled(true)
          },
          (error) => {
            console.error("Error fetching location:", error);
          }
        );
      } else if (permissionStatus.state === "prompt") {
        navigator.geolocation.getCurrentPosition(
          () => {
            dispatch(
              setToastMessage({
                type: toastMessages.TYPE.SUCCESS,
                message: toastMessages.MESSAGE.LOCATION_ACCESS_GRANTED,
                show: true,
              })
            );
            setIsLocationEnabled(true)
          },
          (error) => {
            console.error("Error fetching location:", error);
          }
        );
      } else if (permissionStatus.state === "denied") {
        dispatch(
          setToastMessage({
            type: toastMessages.TYPE.ERROR,
            message: toastMessages.MESSAGE.LOCATION_ACCESS_BLOCKED,
            show: true,
          })
        );
       
      }
    } catch (error) {
      console.error("Error checking location permission:", error);
      dispatch(
        setToastMessage({
          type: toastMessages.TYPE.ERROR,
          message: toastMessages.MESSAGE.UNABLE_TO_CHECK_LOCATION,
          show: true,
        })
      );
     
    }
  };
  

  const onSelectAddress = (addressData: Address) => {
    const { landmark = "", city = "", address = "", _id, contactNumber } = addressData;
    dispatch(
      setCheckoutData({
        currentLocation: "",
        addressLandmark: landmark,
        inputAddress: address,
        city,
        selectedAddressId: _id,
        contactNumber: contactNumber || userPhoneNumber,
      })
    );
    onClose();
  };

  const updateShadows = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      setIsTopShadow(scrollTop > 0);
      setIsBottomShadow(scrollHeight - 1 > scrollTop + clientHeight);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      updateShadows();
      container.addEventListener("scroll", updateShadows);
    }
    
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateShadows);
      }
    };
  }, [containerRef.current]);
  useEffect(()=>{
    checkLocationEnabled(); 
  },[open])
  return (
    <div>
      <Drawer open={open} onClose={onClose}>
        { !isLocationEnabled && (
          <div className="location-enable-container">
            <div className="location-enable">
              <img
                className="location-enable-image"
                src="/Images/location-disable-icon.png"
              />
              <div className="location-enable-center">
                <div className="location-enable-heading">
                  Device location not enabled
                </div>
                <div className="location-enable-text">
                  Enable for better delivery
                </div>
              </div>
            </div>
            <div
              className="location-enable-button"
              onClick={handleEnableLocation}
            >
              Enable
            </div>
          </div>
        )}

        <div
          className="add-new-address"
          onClick={() => navigate("/add-address")}
        >
          <div className="add-new-address-plus">+</div>
          <div className="add-new-address-text">Add a new address</div>
        </div>

       {isLocationEnabled && <div
          className="current-address-container"
          onClick={() => {
            dispatch(
              setCheckoutData({
                currentLocation: currentLocation,
                inputAddress:currentLocation
              })
            );
         
            handleResyncLocation();
            setIsCurrentLocationSelected(true);
            onClose();
          }}
        >
          <img
            className="current-address-container-img"
            src="https://blinkit.com/74d07670e739013817b9.svg"
          />
          <p className="current-address-text">Use Current Location</p>
        </div>}

        <div className="address-drawer-container">
          <div
            ref={containerRef}
            className={`address-list-container ${
              isTopShadow ? "address-list-top-shadow" : ""
            } ${isBottomShadow ? "address-list-bottom-shadow" : ""}`}
          >
            {addresses?.length > 0 ? (
              addresses.map((addressData, index) => (
                <div
                  key={index}
                  className="address-drawer-checkbox-with-text"
                  onClick={(e) => {
                    e.preventDefault();
                    onSelectAddress(addressData);
                    setIsCurrentLocationSelected(false);
                  }}
                >
                  <input
                    type="radio"
                    checked={
                     !isCurrentLocationSelected &&
                      addressData?._id === selectedAddressId
                    }
                    className="address-drawer-checkbox"
                  />
                  <div className="address-drawer-text-container">
                    <p className="address-drawer-primary-text">
                      {username}
                      {username && addressData.pincode && ","}
                      {addressData.pincode}
                    </p>
                    <p
                      className={`address-drawer-secondary-text ${
                        !username &&
                        !addressData.pincode &&
                        "address-drawer-primary-text"
                      }`}
                    >
                      {addressData?.address} {addressData?.city}
                      {addressData?.landmark}
                    </p>
                    {addressData?.contactNumber && (
                      <p className="address-drawer-secondary-text">
                        Mob: {addressData?.contactNumber}
                      </p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div>You do not have saved addresses</div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddressDrawer;
