import { useEffect, useState } from "react";
import { Product, setProductIdPDP } from "../../appState/componentSlices/allProductsFeed";
import { CartQuantityButton } from "../cartQuantityButton/CartQuantityButton";
import { Accordion } from "../accordion/Accordion";
import { useSelector } from "react-redux";
import { RootState } from "../../appState/store";
import { useAppDispatch } from "../../appState/reduxTypedHooks";
import { useLocation, useNavigate } from "react-router";
import Image from "../image/Image";
import { quantityConstants } from "../../constant/titles";
type ProductCardProps = {
  product: Product;
  isSlab?: boolean
};
function ProductCard(props: ProductCardProps) {
  const { product, isSlab = true } = props;
  const productsInCart = useSelector((state: RootState) => state.productInCart.productInCart.products || []);
  const productId = product._id;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const openProductDescriptionPage = () => {
    if(productId) {
      dispatch(setProductIdPDP(productId));
      if(pathname !== "/product-description"){
        navigate("/product-description", { state: { productId }});
      } 
    }
  }

  const productCounterHandler = () => {
    for (const cartProduct of productsInCart) {
      const { productQuantity, productDetails } = cartProduct
      if (product._id === productDetails._id) {
        return (productQuantity);
      }
    }
    return 0
  };

  const { images = [] } = product;


  const productImage = images[0]?.secureUrl || "";


  const {
    itemName = "",
    itemMRPperUnit = 0,
    itemSellingPricePerUnit = 0,
    quantityUnitName="",
    itemPerUnitQuantity=0

  } = product;

  const [productQuantity, setProductQuantity] = useState(productCounterHandler());
  const [mrp, setMrp] = useState(itemSellingPricePerUnit);
  const Product = {
    productDetails: product,
  };
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = (e: React.MouseEvent) => {
    e.stopPropagation(); 
    setIsAccordionOpen((prev) => !prev);
  };
  const { slabPricing } = product;
  const [productCopy, setProductCopy] = useState<any>(structuredClone(Product));

  // mrp set when quantity changes with respect to slab
  useEffect(() => {
    let index = 0;
    for (const slab of slabPricing) {
      if (
        productQuantity <= slabPricing[index + 1]?.[1] - 1 ||
        !slabPricing[index + 1]
      ) {
        setProductCopy((prev: { productDetails: any }): any => {
          prev.productDetails.itemSellingPricePerUnit = slab[2];
          return prev;
        });
        setMrp(slab[2]);
        break;
      }
      index++;
    }
  }, [productQuantity]);
  return (
    <div className="product-container" onClick={openProductDescriptionPage}>
      <div className="product-image-btn-container">
        <div className="product-image-container">
          < Image
            className="product-image"
            src={productImage || ""}
            alt={itemName}
         /> 
        </div>
        {product && (
          <CartQuantityButton
            quantity={productQuantity}
            showAddButton={true}
            product={productCopy}
            setProductQuantity={setProductQuantity}
          />
        )}
      </div>

      <div className="product-info">
        {itemPerUnitQuantity && quantityUnitName && (
          <div className="product-unit-info">
            {itemPerUnitQuantity}{" "}
            {quantityConstants[quantityUnitName] || quantityUnitName}
          </div>
        )}

        <div className="product-name multi-ellipsis">{itemName}</div>
        <div className="cart-item-info">
          {itemMRPperUnit > itemSellingPricePerUnit ? (
            <>
              <div className="mrp-sp-container">
                <span className="selling-price product-sp">
                  &#x20B9;{itemSellingPricePerUnit}
                </span>
                <span className="mrp-price product-mrp">
                  MRP
                  <del className="mrp-price product-mrp mrp-amount">
                    &#x20B9;{itemMRPperUnit}
                  </del>
                </span>
              </div>
              {isSlab && Boolean(product?.slabPricing.length) && (
                <div className="slap-accordion">
                  <img
                    className={`accordion-toggle ${
                      isAccordionOpen ? "open" : ""
                    }`}
                    src={
                      isAccordionOpen
                        ? "/Images/downArrow.svg"
                        : "/Images/rightArrow.svg"
                    }
                    alt="toggle"
                    onClick={toggleAccordion}
                  />
                </div>
              )}
              {isAccordionOpen && (
                <table className="salb-table" width="100px">
                <thead>
                  <tr>
                    <th>Qty</th>
                    <th>Price</th>
                  </tr>
                </thead>

                <tbody>
                  {product?.slabPricing.map(
                    (slabs: number[], idx: number, arr: number[][]) => {
                      return (
                        <tr key={idx}>
                          <td>{`
                                  ${slabs[1]}  ${
                            arr[idx + 1]
                              ? `- ${arr[idx + 1][1] - 1}`
                              : "+"
                          }`}</td>
                          <td className="price-align">{slabs[2]}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
              )}
            </>
          ) : (
            <span className="selling-price product-sp">
              &#x20B9;{itemSellingPricePerUnit}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export { ProductCard };
