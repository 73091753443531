import { useEffect, useState } from "react";
import { Accordion, LoadingSpinner, TrackOrder } from "../../components";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { getOrderById } from "../../appState/componentSlices/orderSlice/OrdersSlice";
import { Link } from "react-router-dom";
import { batch } from "react-redux";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";
import { setOrderStatus } from "../../appState/componentSlices/checkoutSlice/checkoutSlice";
import { initiateRazorpayPayment } from "../../utils/razorpay";
import { generateWhatsAppMessage } from "../../utils/generateWhatsAppMessage";
import { parseDeliveryTime } from "../../utils/parseDeliveryTime";


function OrderDetail() {
  const whatsapp = "/Images/whatsapp.svg"
  const params = useParams();
  const navigate = useNavigate();
  const { orderId = "" } = params;
  const dispatch = useAppDispatch();
  const { orderDetail, status }: any = useAppSelector((state) => state.orderStatus);
  const customerName = useAppSelector((state) => state.userOtp.userDetails.name);

  const {
    shippingAddress,
    orderNumber = "",
    orderItems = [],
    timeSlot = "",
    totalPayableAmount = "",
    paymentMethod = "",
    orderStatus = [],
    deliveryCharge = "",
    discountAmount = 0,
    paymentStatus,
    _id,
    isPaid,
    distanceData,
    deliveryTime

  } = orderDetail || {};
  const {
    userOtp: { userDetails },
  } = useAppSelector((state) => state);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [timerExpired, setTimerExpired] = useState<boolean>(false); 
  
  useEffect(() => {
    batch(() => {
      dispatch(setTitle(headers.orderDetail));
      dispatch(getOrderById(orderId));
      dispatch(setDisableFooter(false));
    });
  }, []);

  useEffect(() => {
    if(deliveryTime ){
      if (timerExpired) return;
    const adjustedDeliveryTime = deliveryTime ? parseDeliveryTime(deliveryTime) : 0;
    
    const storedTimeLeft = localStorage.getItem(`timeLeft_${orderId}`);
    const storedTimestamp = localStorage.getItem(`timestamp_${orderId}`);
    const storedTimerExpired = localStorage.getItem(`timerExpired_${orderId}`) === 'true';

    if (storedTimerExpired) {
      setTimerExpired(true);
      return;
    }
    
    if (storedTimeLeft && storedTimestamp) {
      const elapsedTime = Math.floor((Date.now() - Number(storedTimestamp)) / 1000);
      const adjustedTimeLeft = Math.max(Number(storedTimeLeft) - elapsedTime, 0); 
      setTimeLeft(adjustedTimeLeft);
    
    } else {
      
      setTimeLeft(adjustedDeliveryTime + 1200); 
      localStorage.setItem(`timeLeft_${orderId}`, String(adjustedDeliveryTime + 1200));
      localStorage.setItem(`timestamp_${orderId}`, String(Date.now()));
      
    }
  
    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerInterval);
          localStorage.removeItem(`timeLeft_${orderId}`);
          localStorage.removeItem(`timestamp_${orderId}`);
          localStorage.setItem(`timerExpired_${orderId}`, 'true'); 
          setTimerExpired(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  
    return () => clearInterval(timerInterval); 
}}, [deliveryTime, orderId]);
  
  
  const formatTime = (seconds: number) => {
  
    const hours = Math.floor(seconds / 3600); 
    const minutes = Math.floor((seconds % 3600) / 60);
  
    if (hours > 0) {
      return `${hours} hr${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
    }
    return `${minutes} min${minutes > 1 ? 's' : ''}`;
    
  };
  
  const handleOnCompleteOrder = async (isUpiDuePayment = false ) => {    
    initiateRazorpayPayment({ amount: Number(totalPayableAmount), orderId: _id, userDetails, dispatch, navigate, isUpiDuePayment });
    setTimeout(async () => {
      await dispatch(setOrderStatus(false));
    }, 2000);
  };
  const handleWhatsAppMessage = () => {
    const messageUrl = generateWhatsAppMessage(customerName ?? "user", orderNumber);
    window.open(messageUrl, '_blank');
  };


  return (
    <div className="order-detail-container">
      {deliveryTime && (
        <div className="delivery-time-container">
          <p>Estimated Delivery Time</p>
          {timeLeft < 60 ? (
            <h2>Order is slightly delayed</h2>
          ) : (
            <h2>{formatTime(timeLeft)}</h2>
          )}
        </div>
      )}
      {orderStatus[orderStatus.length - 1]?.status ===
        "delivered_successfully" && 
        <div
          onClick={handleWhatsAppMessage}
          className="order-detail-support-container"
        >
          <div className="order-detail-support">
            <div className="order-detail-support-button">
              <img src={whatsapp} alt="" />
            </div>

            <div className="order-detail-support-circle">
              <div className="order-detail-support-circle-text">
                <span>C</span>
                <span>O</span>
                <span>N</span>
                <span>S</span>
                <span>U</span>
                <span>M</span>
                <span>E</span>
                <span>R</span>
                <span>•</span>
                <span>S</span>
                <span>U</span>
                <span>P</span>
                <span>P</span>
                <span>O</span>
                <span>R</span>
                <span>T</span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      }

      {isPaid && paymentStatus || paymentMethod === 'cash' ? (
        (<TrackOrder orderStatus={orderStatus} orderNumber={orderNumber} />)
      ) : isPaid !== undefined && status !== "loading" ? (
        <div className="order-pending-status">
          <h3>Payment pending</h3>
          <button className="complete-payment-button" onClick={()=>handleOnCompleteOrder()}>Pay</button>
        </div>) : <LoadingSpinner />
      }
      <div className="order-details-container">
        <Accordion
          heading={`ORDER #${orderNumber}`}
          height={"30vh"}
          showDetails={true}
        >
          <div className="order-detail">
            <div className="address-info-container">
              <div className="address-icon">
                <img src="/Images/location.svg" alt="address icon" />
              </div>
              <div className="address-info">
                <p>Address</p>
                {shippingAddress?.address.includes("https") ? (
                  <Link to={shippingAddress?.address}>
                    {shippingAddress?.address}
                  </Link>
                ) : (
                  <p>{shippingAddress?.address}</p>
                )}
              </div>
            </div>
            {shippingAddress?.currentLocation && (
              <div className="current-location">
                <p>Live Location: </p>
                <p>{shippingAddress?.currentLocation}</p>
              </div>
            )}
            <div className="slot-payment-mode">
              <p>Slot:</p>
              <p>{timeSlot}</p>
            </div>
            <div className="slot-payment-mode">
              <p>Payment Mode:</p>
              <p>{paymentMethod}</p>
            </div>
            <div className="order-payment-info">
              <p>Discount Amount:</p>
              <p>{discountAmount}</p>
            </div>
            <div className="order-payment-info">
              <p>Delivery Charges:</p>
              <p>{deliveryCharge}</p>
            </div>
            <div className="order-payment-info">
              <p>Total Amount:</p>
              <p>{totalPayableAmount}</p>
            </div>
            {paymentMethod.toLowerCase() === "cash" && !isPaid &&
              <div className="order-payment-info">
                <p>Pay with UPI:</p>
                <button
                  className="order-payment-info-complete-payment-button"
                  onClick={()=>handleOnCompleteOrder(true)}
                >Pay</button>
              </div>}
          </div>
        </Accordion>
      </div>
      <div className="order-items-container">
        <Accordion heading="Description" height={"30vh"} showDetails={true}>
          <div>
            {orderItems?.length ? (
              orderItems.map((item: any) => {
                const { product, quantity, price } = item;
                return (
                  <div className="child-item-container" key={product.itemName}>
                    <div className="item-image-container">
                      <img
                        src={
                          product?.images?.length
                            ? product?.images?.[0]?.secureUrl
                            : ""
                        }
                        alt="Item Image"
                      />
                    </div>
                    <div className="item-info-container">
                      <p className="item-name">
                        {product.itemName} &nbsp; <span>({quantity})</span>
                      </p>
                     {product?.itemSellingPricePerUnit && <p className="item-price">{product?.itemSellingPricePerUnit} Rs.</p>}
                      <p className="item-total">Total : {price}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default OrderDetail;
