import React, { useEffect, useRef } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

interface SpeechRecognitionProps {
  startImmediately?: boolean; 
  language?: string; 
  onStart?: () => void; 
  onStop?: () => void; 
  onError?: (error: string) => void; 
  continuous?: boolean; 
  setQuery: (newValue: string) => void; 
}

export const SpeechRecognitionUtility: React.FC<SpeechRecognitionProps> = ({
  startImmediately = false,
  language = "en-US",
  onStart,
  onStop,
  onError,
  continuous = true,
  setQuery,
}) => {
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    resetTranscript,
  } = useSpeechRecognition();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (startImmediately && browserSupportsSpeechRecognition) {
      startListening();
    }
    return () => {
      stopListening();
    };
  }, [startImmediately, browserSupportsSpeechRecognition]);

  useEffect(() => {
    if (listening) {
      clearTimeout(timeoutRef.current as NodeJS.Timeout);
      timeoutRef.current = setTimeout(() => {
        stopListening();
      }, 5000);
    }
  }, [transcript, listening]);

  useEffect(() => {
    if (transcript) {
      setQuery(transcript); 
    }
  }, [transcript, setQuery]);

  const startListening = () => {
    if (!listening && browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous, language });
      if (onStart) onStart();
    }
  };

  const stopListening = () => {
    if (listening) {
      SpeechRecognition.stopListening();
      if (onStop) onStop();
    }
  };

  const handleClickMic = () => {
    if (listening) {
      stopListening();
    } else {
      startListening();
    }
    resetTranscript();
  };

  if (!browserSupportsSpeechRecognition) {
    window.alert("Your browser does not support speech recognition.");
  }

  return (
    <div>
      <img
        src={listening ? "/Images/listeningMic.svg" : "/Images/mic.svg"}
        alt="Microphone"
        height={ listening? "22px" : "20px" }
        width={listening? "22px" : "20px" }
        onClick={handleClickMic}
        style={{ cursor: "pointer",marginRight: "9px", marginTop:"4px"}}
      />
    </div>
  );
};
