import React from "react";
import { useNavigate } from "react-router-dom";
import { TabBarProps } from "../../typeDefination";

const TabBar: React.FC<TabBarProps> = ({ tabs }) => {
  const navigate = useNavigate();

  const handleTabClick = (label: string) => {
    if (label === "All") {
        navigate(`/categories`); 
      } else {
        navigate(`/Categories/${label}`);
      }
  };


  return (
    tabs && (
        <div className="tab-bar-container">
      <div className="tab-bar">
        {tabs.map((tab, index) => {
          const currentPath = decodeURIComponent(location.pathname);
          const isActive =
          tab.label === "All"
            ? currentPath === "/categories"
            : currentPath === `/Categories/${tab.label}`; 

          return (
            <div
              key={index}
              className={`tab-item ${isActive ? "active-tab" : ""}`}
              onClick={() => handleTabClick(tab.label)}
            >
             <img src={tab.image} alt="" className={`tab-icon ${isActive ? "active" : ""}`} />

              <span>{tab.label}</span>
            </div>
          );
        })}
      </div>
      </div>
    )
  );
};

export { TabBar };
