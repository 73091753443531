import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useAppSelector } from "../../reduxTypedHooks";
import { AddressSearchState } from "../../../typeDefination";



const initialState: AddressSearchState = {
  query: "",
  suggestions: [],
  loading: false,
  error: null,
  selectedAddress: null, 
  finalSelectedAddress:null,
  landmark:"",
  outsideRangeMessage: null,
};

export const fetchAddressSuggestions = createAsyncThunk(
  "addressSearch/fetchSuggestions",
  async ({ query, currentlocation }: { query: string; currentlocation: string | any }, { rejectWithValue }) => {
    try {
      
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/search-location`, 
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query,
            currentlocation
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch suggestions");
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addressSearchSlice = createSlice({
  name: "addressSearch",
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
      state.query = action.payload.address;
    },
    setFinalSelectedAddress: (state, action) => {
      state.finalSelectedAddress = action.payload;
      state.query = action.payload.address;
    },
    resetState: (state) => {
      state.query = "";
      state.suggestions = [];
      state.loading = false;
      state.error = null;
      state.selectedAddress = null;
      state.outsideRangeMessage = null;
    },
    setLandmark(state, action) {
      state.landmark = action.payload;
    },
    setOutsideRangeMessage(state, action) {
      state.outsideRangeMessage = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddressSuggestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAddressSuggestions.fulfilled, (state, action) => {
        state.loading = false;
        state.suggestions = action.payload.data;
      })
      .addCase(fetchAddressSuggestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setQuery, resetState ,setSelectedAddress,setFinalSelectedAddress,setLandmark ,setOutsideRangeMessage} = addressSearchSlice.actions;


