// create redux thunk and slice for login

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../utils/api/apiPaths";
import setCookie from "../../../utils/setCookie";
import getCookie from "../../../utils/getCookie";
import clearCookie from "../../../utils/clearCookie";


const initialState = {
  userOtp: {},
  userDetails: {
    name: "",
    phone: 0,
    email: "",
    addresses: [],
    createdDate: "",
    image:{},
    _id: "",
    guest: false,
  },
  loading: false,
  error: null,
  isLoggedIn:
    JSON.parse(sessionStorage.getItem("isLoggedIn") || "{}")?.status || false,
  showModal: false,
  otpScreen: false,
  phoneNumber: "",
  isVerify: false,
  otpLength: 6,
  message: ""
};
interface VerifyOtpPayload {
  phoneNumber: string;
  otp: string;
  guestUserId?:string,
}

const THUNK_METHODS = {
  VERIFY_OTP: "verifyOtp",
  GET_USER_DETAILS: "getUserDetails",
  LOGOUT_USER: "user/logout",
  UPDATE_USER_DETAILS: "updateUserDetails",
  UPDATE_USER_IMAGE:"updateUserImage"
};

// creating redux thunk for login

export const loginUser = createAsyncThunk(
  "userLogin/userLogin",
  async (phone: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.USER.LOGIN_USER}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phone }),
    });

    const data = await response.json();
    return data;
  }
);

// for verifying otp
export const verifyOtp = createAsyncThunk(
  "userLogin/verifyOtp",
  async (params: VerifyOtpPayload, { rejectWithValue }) => {
    try {
      const token = getCookie('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.USER.VERIFY_OTP}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(token && { Authorization: token }),
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to verify OTP");
      }

      const data = await response.json();
      if (data?.data?.token) {
        setCookie('token', data.data.token);
        window.location.reload();
      }
      return data; // Return success payload
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendOtp = createAsyncThunk(
  "userLogin/sendOtp",
  async (phoneNumber: string, { rejectWithValue }) => {
    try {
      const token = getCookie('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.USER.SEND_OTP}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(token && { Authorization: token }),
        },
        body: JSON.stringify({ phoneNumber }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send OTP");
      }

      const data = await response.json();

      return data; // Return success payload
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// get user details
export const getUserDetails = createAsyncThunk(
  THUNK_METHODS.GET_USER_DETAILS,
  async () => {
    const token = getCookie('token');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.USER.GET_USER_DETAILS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: token }),
      },
    }).then((response) => response);

    return response.json();
  }
);

// for updating user details
export const updateUserDetails = createAsyncThunk(
  THUNK_METHODS.UPDATE_USER_DETAILS,
  async (params: { name: string }) => {
    const token = getCookie('token');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.USER.UPDATE_USER_DETAILS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: token }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    return data;
  }
);

export const updateUserImage = createAsyncThunk(
  THUNK_METHODS.UPDATE_USER_IMAGE,
  async (formData: FormData ) => {
    const token = getCookie('token');    
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.USER.UPDATE_USER_IMAGE}`, {
      method: "POST",
      headers: {
        ...(token && { Authorization: token }),
      },
      body: formData ,
    });

    const data = await response.json();
    return data;
  }
);

export const logoutUser = createAsyncThunk(
  THUNK_METHODS.LOGOUT_USER,
  async () => {
    const token = getCookie('token');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.USER.LOGOUT_USER}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: token }),
      },
    }).then((response) => {
      clearCookie("token");
      sessionStorage.removeItem("isLoggedIn")
      return response
    });
    return response;
  }
);

export const loginGuestUser = createAsyncThunk(
  "loginGuestUser/loginGuestUser",
  async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.USER.GUEST_USER}`, {
      method: "POST",
    });
    const data = await response.json();
    if (data?.data?.token) {
      setCookie('token', data.data.token);
    }
    return data;
  }
);

export const verifyUserContact = createAsyncThunk(
  "userLogin/verifyUserContact",
  async (params: VerifyOtpPayload, { rejectWithValue }) => {
      const token = getCookie('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.USER.VERIFY_USER_CONTACT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(token && { Authorization: token }),
        },
        body: JSON.stringify(params),
      });
      const data = await response.json();
      return data; 
  }
);

export const mergeUserProductFromCart = createAsyncThunk(
  "user/mergeUserProductFromCart",
  async (params: { guestUserId:string, phoneNumber:string }) => {
    const token = getCookie('token');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.CART.MERGE_FROM_CART}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: token }),
      },
      body: JSON.stringify(params),
    });
    const data =  await response.json();
    if (data?.data?.token) {
      setCookie('token', data.data.token);
      window.location.reload();
    }
    return data
  }
);

// creating slice for login

export const userLoginSlice = createSlice({
  name: "userLogin",
  initialState,
  reducers: {
    userLoginSuccess: (state, action) => {
      state.userOtp = action.payload;
      state.loading = false;
      state.error = null;
    },
    setModal: (state, action) => {
      state.showModal = action.payload;
    },
    setOtpScreen: (state, action) => {
      state.otpScreen = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setVerify: (state, action) => {
      state.isVerify = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.userOtp = action.payload;
      state.loading = false;
      state.error = null;
      state.isLoggedIn = true;
      sessionStorage.setItem(
        "isLoggedIn",
        JSON.stringify({ status: true, ...action.payload })
      );
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(verifyOtp.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.showModal = false;
      sessionStorage.setItem("isLoggedIn", JSON.stringify(action.payload));
      state.userOtp = action.payload;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sendOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload?.message;
      // Optionally handle success data here if needed
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any; // Capture the error message
    });
    builder.addCase(getUserDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {

      state.loading = false;
      state.userDetails = action.payload.data;
      state.isLoggedIn = Boolean(action.payload.data && !state.userDetails.guest);
      sessionStorage.setItem(
        "isLoggedIn",
        JSON.stringify({ status: true, ...action.payload })
      );
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(logoutUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.showModal = false;
      state.otpScreen = false;
      state.phoneNumber = "";
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.loading = true;
      state.phoneNumber = "";
    });
    builder.addCase(updateUserDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetails = action.payload.data;
    });
    builder.addCase(updateUserDetails.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateUserImage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUserImage.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetails.image = action.payload.data || {};
    });
    builder.addCase(updateUserImage.rejected, (state, action) => {
      state.loading = false;
    });
    builder
    .addCase(loginGuestUser.pending,(state,action)=>{
      state.loading=true;
    })
    .addCase(loginGuestUser.fulfilled,(state,action)=>{
      state.loading = false;
      state.userDetails = action.payload?.data?.userData
    })
    .addCase(loginGuestUser.rejected,(state,action)=>{
      state.loading = false;
    })
    builder
    .addCase(verifyUserContact.pending,(state,action)=>{
      state.loading = true;
    })
    .addCase(verifyUserContact.fulfilled,(state,action)=>{
      state.loading = false;
      state.userDetails = action?.payload?.data;
      state.message = action?.payload?.message;
      state.showModal = false;
    })
    .addCase(verifyUserContact.rejected,(state,action)=>{
      state.loading = false;
    })
    .addCase(mergeUserProductFromCart.pending,(state,action)=>{
      state.loading = true;
    })
    .addCase(mergeUserProductFromCart.fulfilled,(state,action)=>{
      state.loading = false;
      state.message = action?.payload?.message;
      sessionStorage.setItem(
        "isLoggedIn",
        JSON.stringify({ status: true, ...action.payload })
      );
    })
    .addCase(mergeUserProductFromCart.rejected,(state,action)=>{
      state.loading = false;
    })
  },
});

export const {
  userLoginSuccess,
  setModal,
  setOtpScreen,
  setPhoneNumber,
  setVerify,
  setLoading,
  setMessage,
} = userLoginSlice.actions;
