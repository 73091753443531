import { useLocation } from "react-router";
import { useAppSelector } from "../../appState/reduxTypedHooks";
import {
  Card,
  ProductCard,
} from "../../components";
import LoadingSpinner from "../../components/loader/LoadingSpinner";

const SearchProductList = () => {
  const loader = useAppSelector((state) => state.ProductsData.plpLoader);
  const { state } = useLocation();
  const searchedProductsFromState = useAppSelector((state) => state.ProductsData.searchedProducts || {});
  const searchedProducts = searchedProductsFromState.data.length > 0 ? searchedProductsFromState : (state?.searchedProducts ?? {});

  return (
    <div>
      <div className="products-container-main">
        <div className="products-container">
          {!loader ? (
            searchedProducts?.data?.length > 0
              ? searchedProducts?.data?.map((product: any, index: number) => (
                <Card key={index} style={{maxWidth:'120px'}}>
                  <ProductCard product={product} />
                </Card>
              ))
              : <div
                className="no-result-search-message"
              >
                {searchedProducts.message}
              </div>
          ) : (
            <div className="spinner-container-main">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );

};

export { SearchProductList };
